(function (app, core, common) {
    common.ContentList = function (widget) {
        const _self = this;

        _self.element = widget;

        _self.itemsPerPage = _self.element.getAttribute('data-items-per-page');
        _self.slideContainer = _self.element.querySelector(
            '.js-content-slider-content'
        );
        _self.sliderElements =
            _self.slideContainer.querySelectorAll('.js-thumb');
        _self.nextBtn = _self.element.querySelector('.js-slide-next');
        _self.prevBtn = _self.element.querySelector('.js-slide-prev');
        _self.scrollList = _self.element.querySelector('.js-scroll-list');
        _self.paginatorContainer = _self.element.querySelector('.js-paginator');

        _self.itemsPerPageDesktop =
            _self.element.getAttribute('data-items-per-page-desktop') || '';
        _self.itemsPerPageTablet =
            _self.element.getAttribute('data-items-per-page-tablet') || '';

        const config = {
            container: _self.element,
            itemsPerPage: _self.itemsPerPage,
            itemsPerPageDesktop: _self.itemsPerPageDesktop,
            itemsPerPageTablet: _self.itemsPerPageTablet,
            scrollContainer: _self.slideContainer,
            sliderElements: _self.sliderElements,
            nextButton: _self.nextBtn,
            prevButton: _self.prevBtn,
            scrollList: _self.scrollList,
            nudgeLeftClass: 'nudge-left',
            nudgeRightClass: 'nudge-right',
            pagerActiveClass: '',
            paginatorContainer: _self.paginatorContainer
        };

        new common.ContentCarousel(config);
    };

    app.widgetInitialiser.addMultipleWidgetsByName(
        'content-carousel',
        common.ContentList
    );
})(PULSE.app, PULSE.core, PULSE.app.common);
