(function (common, core) {
    'use strict';

    const INPUT_TYPES = ['click', 'keypress'];
    const MOUSE_DOWN = ['mousedown', 'touchdown'];
    const NO_KEYBOARD_FOCUS = 'js-no-keyboard-focus';
    const KEY_CODES = {
        ENTER: 13,
        ESCAPE: 27,
        ARROW_KEY_LEFT: 37,
        ARROW_KEY_RIGHT: 39,
        SPACE: 32
    };

    common.addAriaClickListener = (
        element,
        callback,
        acceptSpace = false,
        useMousedown = false,
        useCapture = false
    ) => {
        // Sometimes when we don't want focus to occur on click we need to bind the mousedown event instead of click.
        const typesToUse = useMousedown
            ? INPUT_TYPES.filter((type) => type !== 'click').concat(MOUSE_DOWN)
            : INPUT_TYPES;
        const clickEvtType = useMousedown ? MOUSE_DOWN : 'click';

        const callbackWrapper = (evt) => {
            if (_hasInteracted(evt, acceptSpace, clickEvtType)) {
                return callback(evt);
            }

            return null;
        };

        for (let i = 0; i < typesToUse.length; i++) {
            element.addEventListener(
                typesToUse[i],
                callbackWrapper,
                useCapture
            );
        }

        return callbackWrapper;
    };

    common.addMultiAriaClickListener = (
        elements,
        callback,
        acceptSpace = false,
        useMousedown = false
    ) => {
        const elArray = Array.prototype.slice.call(elements);
        elArray.forEach((element) =>
            common.addAriaClickListener(
                element,
                callback,
                acceptSpace,
                useMousedown
            )
        );
    };

    common.removeAriaClickListener = (
        element,
        callback,
        useMousedown = false
    ) => {
        // Sometimes when we don't want focus to occur on click we need to bind the mousedown event instead of click.
        const typesToUse = useMousedown
            ? INPUT_TYPES.filter((type) => type !== 'click').concat(MOUSE_DOWN)
            : INPUT_TYPES;

        for (let i = 0; i < typesToUse.length; i++) {
            element.removeEventListener(typesToUse[i], callback);
        }
    };

    common.resetAriaMenuAttributes = (element) => {
        element.setAttribute('aria-expanded', 'false');
        element.setAttribute('aria-hidden', 'true');
    };

    /**
     * Toggles any aria attributes for menu for the given element. Determines whether it needs to enable/disable the relevant
     * aria attributes.
     *
     * @param {HTMLElement} element - The element to toggle the ARIA menu attributes on/off
     */
    common.toggleAriaMenuAttributes = (element) => {
        const ariaExpanded = element.getAttribute('aria-expanded');
        const ariaHidden = element.getAttribute('aria-hidden');

        if (typeof ariaExpanded !== 'undefined') {
            const toggledExpandedValue =
                ariaExpanded === 'true' ? 'false' : 'true';
            element.setAttribute('aria-expanded', toggledExpandedValue);
        }

        if (typeof ariaHidden !== 'undefined') {
            const toggledHiddenValue = ariaHidden === 'true' ? 'false' : 'true';
            element.setAttribute('aria-hidden', toggledHiddenValue);
        }
    };

    /**
     * Toggles any anchor elements tabindex on the given element. This is to prevent anchors taking keyboard focus
     * when the element is not in view.
     *
     * @param {HTMLElement} element - The element to toggle the tabindex on/off on
     * @param {string}      nodeType - Defaults to anchor tag but allows users to set tab index on non-anchor elements
     */
    common.toggleZIndex = (element, nodeType = 'a') => {
        const linkElements = [].slice.call(element.querySelectorAll(nodeType));

        for (let i = 0; i < linkElements.length; i++) {
            const hasDisabledZIndex =
                linkElements[i].getAttribute('tabindex') === '-1';

            // Should not manipulate z-index on elements that explicitly do not want keyboard focus
            if (core.style.hasClass(linkElements[i], NO_KEYBOARD_FOCUS)) {
                continue;
            }

            if (hasDisabledZIndex && _elementHasDefaultTabindex(nodeType)) {
                linkElements[i].removeAttribute('tabindex');
            } else if (hasDisabledZIndex) {
                linkElements[i].setAttribute('tabindex', '0');
            } else {
                linkElements[i].setAttribute('tabindex', '-1');
            }
        }
    };

    const _elementHasDefaultTabindex = (nodeType) => {
        return nodeType === 'a';
    };

    const _hasInteracted = (evt, acceptSpace, clickEvtType) => {
        const clickEvtInteracted = Array.isArray(clickEvtType)
            ? clickEvtType.includes(evt.type)
            : evt.type === clickEvtType;

        if (evt.which === KEY_CODES.ENTER || clickEvtInteracted) {
            return true;
        }

        if (acceptSpace && evt.which === KEY_CODES.SPACE) {
            return true;
        }

        return false;
    };
})(PULSE.app.common, PULSE.core);
