(function (app, core) {
    'use strict';

    const YEAR = 365;
    const COOKIE_EXPIRY = YEAR * 2; //Set cookie expiry to 2 years

    /**
     * Cookie Toggle
     *
     * @param {HTMLElement} [element] the HTML element of the cookie being toggled (should be container for 'cookieName')
     * @property {HTMLElement} [acceptBtn] the HTML element of the 'accept cookies' button
     * @property {HTMLElement} [refuseBtn] the HTML element of the 'decline cookies' button
     * @property {string} [cookieName] the name of the cookie that has been clicked
     * @property {string} [exclusionCookieName] the name of the cookie that will be set in the browser
     * @property {Function} [renderButton] Evaluates button state
     * @property {Function} [listeners] Sets event listners on accept/decline cookie buttons
     */
    app.CookieToggle = function (element) {
        const _self = this;

        _self.acceptBtn = element.querySelector('.js-accept-btn');
        _self.declineBtn = element.querySelector('.js-refuse-btn');
        _self.cookieName = element.dataset.cookieName;
        _self.exclusionCookieName = `${_self.cookieName}_exclusion`;
        _self.renderButton();
        _self.listeners();
    };

    /**
     *  Evaluates the state of each cookie button to show accept/decline depending on whether cookie is set
     */
    app.CookieToggle.prototype.renderButton = function () {
        const _self = this;
        const HIDE_CLASS = 'u-hide';
        const currentState = core.localStorage.getStorage(
            _self.exclusionCookieName,
            true
        );

        // Logic to decide whether to show/hide each cookie accept & decline button
        if (currentState) {
            core.style.removeClass(_self.acceptBtn, HIDE_CLASS);
            core.style.addClass(_self.declineBtn, HIDE_CLASS);
        } else {
            core.style.addClass(_self.acceptBtn, HIDE_CLASS);
            core.style.removeClass(_self.declineBtn, HIDE_CLASS);
        }
    };

    /**
     *  Listnens out for click event on the cookie buttons
     *      - adds/removes opt out cookie into the browser for particular cookie
     *      - fires custom cookieEvent to be hooked onto when a cookie needs removing
     *      - calls render button function to rerender the button depending on it's cookie state
     */
    app.CookieToggle.prototype.listeners = function () {
        const _self = this;

        _self.declineBtn.addEventListener('click', () => {
            core.localStorage.setStorage(
                _self.exclusionCookieName,
                true,
                COOKIE_EXPIRY,
                true,
                app.environment.domain
            );
            let cookieEvent = new CustomEvent('cookieDeclined', {
                detail: _self.cookieName
            });
            window.dispatchEvent(cookieEvent);
            _self.renderButton();
        });

        _self.acceptBtn.addEventListener('click', () => {
            core.localStorage.clearStorage(
                _self.exclusionCookieName,
                true,
                app.environment.domain
            );
            let cookieEvent = new CustomEvent('cookieAdded', {
                detail: _self.cookieName
            });
            window.dispatchEvent(cookieEvent);
            _self.renderButton();
        });
    };

    app.widgetInitialiser.addMultipleWidgetsByName(
        'cookie-toggle',
        app.CookieToggle
    );
})(PULSE.app, PULSE.core);
