( function( app, common ) {
    'use strict';

    const TEMPLATE = 'common.transition-title';

    common.transitionTitle = function( title, titleClass, isReverse=false, isScore=false, noDelay=false ) {
        const splitTitle = title.split('');
        const model = {
            titleClass: titleClass,
            splitTitle: splitTitle,
            isReverse: isReverse ? 'is-reverse' : '',
            isScore: isScore ? 'is-score' : '',
            noDelay: noDelay ? 'no-delay' : ''
        }
        return app.templating.render( { model }, TEMPLATE );
    };

}( PULSE.app, PULSE.app.common ) );
