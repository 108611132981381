/* eslint-disable complexity */

(function (app, common, core) {
    'use strict';

    const DISABLED_CLASS = 'is-disabled';
    const HIDE_CLASS = 'u-hide';
    const FADED_OUT = 0.4;
    const ONE = 1;
    const NO_HOVER_STATE = 'no-hover-state';

    const disable = function (element) {
        if (element) {
            core.style.addClass(element, DISABLED_CLASS);
        }
    };

    const enable = function (element) {
        if (element) {
            core.style.removeClass(element, DISABLED_CLASS);
        }
    };

    const WIDE_ITEMS = 4;
    const DESKTOP_ITEMS = 4;
    const TABLET_ITEMS = 3;
    const PHABLET_ITEMS = 2;
    const MOBILE_ITEMS = 1;

    const OFFSET_BUFFER = 24;
    const HALF_SECOND = 500;

    /**
     * Constructor for common.Carousel
     * This constructor, when initiated on a widget will active carousel functionality
     * On mobile, a manual scroll functionality will be set instead
     *
     * @param {object} widget - parent widget
     * @param {object} config - configuration object
     * @class
     */
    common.Carousel = function (widget, config = {}) {
        const _self = this;

        _self.widget = widget;
        _self.config = config;

        _self.carousel = widget.querySelector('[data-widget="carousel"]');
        _self.pagination = _self.widget.querySelector(
            config.pagination || '.js-pagination'
        );
        _self.paginationLength = _self.widget.querySelector(
            '.js-pagination-length'
        );
        _self.paginationIndex = _self.widget.querySelector(
            '.js-pagination-index'
        );
        _self.slideContainer = _self.widget.querySelector(
            config.slideContainerClass || '.js-slide-container'
        );
        _self.items = _self.widget.querySelectorAll(
            config.itemClass || '.js-item'
        );
        _self.previousArrow = _self.widget.querySelector(
            config.previousArrow || '.js-previous'
        );
        _self.nextArrow = _self.widget.querySelector(
            config.nextArrow || '.js-next'
        );
        _self.mobileScroll = _self.widget.querySelector(
            config.mobileScroll || '.js-mobile-scroll'
        );
        _self.mobileNextButton = _self.widget.querySelector(
            config.mobileNextButton || '.js-slider-mobile-next'
        );
        _self.mobilePreviousButton = _self.widget.querySelector(
            config.mobilePreviousButton || '.js-slider-mobile-previous'
        );
        _self.contentType = widget.dataset.contentType;
        _self.numItems = _self.items.length;

        _self.breakpoint = {
            wide: config.breakpointWide || app.breakpoints.wide,
            desktop: config.breakpointDesktop || app.breakpoints.desktopMin,
            tablet: config.breakpointTablet || app.breakpoints.tabletMin,
            phablet: config.breakpointPhablet || app.breakpoints.phabletMin,
            mobile: config.breakpointMobile || app.breakpoints.mobileMin
        };

        _self.pageItems = {
            wide: config.pageItemsWide || WIDE_ITEMS,
            desktop: config.pageItemsDesktop || DESKTOP_ITEMS,
            tablet: config.pageItemsTablet || TABLET_ITEMS,
            phablet: config.pageItemsPhablet || PHABLET_ITEMS,
            mobile: config.pageItemsMobile || MOBILE_ITEMS
        };

        _self.isCarouselMode = window.matchMedia(
            '(min-width: ' + _self.breakpoint.phablet + 'px)'
        ).matches
            ? true
            : false;
        _self.highlightItem = _self.widget.querySelector(
            '[ data-highlight-item ]'
        );
        _self.highlightIndex = _self.highlightItem
            ? Number(_self.highlightItem.getAttribute('data-index'))
            : null;

        if (!core.style.hasClass(_self.widget, 'no-carousel')) {
            if (_self.isCarouselMode) {
                core.event.windowResize.add({
                    method: _self.configureCarousel.bind(_self)
                });
            }
            _self.configureCarousel();
            _self.setEventListeners();
        }
    };

    /**
     * Sets event listeners on interactive elements
     */
    common.Carousel.prototype.setEventListeners = function () {
        const _self = this;

        common.addAriaClickListener(_self.previousArrow, () => {
            if (_self.activePage !== ONE) {
                _self.activePage--;
                _self.isCarouselMode
                    ? _self.slideToPagePrevious()
                    : _self.moveOffsetPrevious();
                _self.updatePagination();
            }
        });

        common.addAriaClickListener(_self.nextArrow, () => {
            if (_self.activePage !== _self.pages) {
                _self.activePage++;
                _self.isCarouselMode
                    ? _self.slideToPageNext()
                    : _self.moveOffsetNext();
                _self.updatePagination();
            }
        });

        if (_self.mobileNextButton) {
            common.addAriaClickListener(_self.mobileNextButton, () => {
                _self.moveOffsetNext();
            });
        }

        if (_self.mobilePreviousButton) {
            common.addAriaClickListener(_self.mobilePreviousButton, () => {
                _self.moveOffsetPrevious();
            });
        }

        _self.slideContainer.addEventListener('scroll', function () {
            _self.checkScrollPosition();
        });
    };

    common.Carousel.prototype.updatePagination = function () {
        const _self = this;

        if (_self.activePage === _self.pages) {
            _self.nextArrow.style.opacity = FADED_OUT;
            core.style.addClass(_self.nextArrow, NO_HOVER_STATE);
        } else {
            _self.nextArrow.style.opacity = ONE;
            core.style.removeClass(_self.nextArrow, NO_HOVER_STATE);
        }

        if (_self.activePage === 1) {
            _self.previousArrow.style.opacity = FADED_OUT;
            core.style.addClass(_self.previousArrow, NO_HOVER_STATE);
        } else {
            _self.previousArrow.style.opacity = ONE;
            core.style.removeClass(_self.previousArrow, NO_HOVER_STATE);
        }

        _self.paginationIndex.innerHTML = _self.activePage;
    };

    /**
     * Calculate screensize, number of items to a page, and item width
     * Apply specific inline styles to handle type of scroll functionality (manual or carousel)
     */
    common.Carousel.prototype.configureCarousel = function () {
        const _self = this;

        const firstItem = _self.items[0];

        const itemWidth = window.getComputedStyle(firstItem).width;
        _self.itemWidth = Number(itemWidth.split('px')[0]);

        const marginRight = window.getComputedStyle(firstItem).marginRight;
        _self.gutterWidth = Number(marginRight.split('px')[0]);

        _self.sliderWidth = _self.carousel.offsetWidth;
        _self.itemsPerPage = _self.getItemsPerPage();

        _self.isSinglePage =
            _self.numItems <= _self.itemsPerPage ? true : false;

        _self.numPages = Math.ceil(_self.numItems / _self.itemsPerPage);

        _self.pageIndex = _self.pageIndex ? _self.pageIndex : 0;
        _self.isFirstPage = true;

        if (_self.pagination) {
            _self.pages = Math.ceil(_self.numItems / _self.itemsPerPage);
            _self.paginationLength.innerHTML = _self.pages;
            _self.activePage = 1;
        }

        if (_self.isCarouselMode) {
            _self.isSinglePage ? _self.hideControls() : _self.showControls();

            if (!_self.isSinglePage) {
                _self.setSliderPosition(); // don't run if less than 4 items
            }
        } else {
            _self.setAllItemsActive();
            _self.slideContainer.style.left = '0px';
        }

        if (
            _self.highlightIndex &&
            !_self.isSinglePage &&
            _self.isCarouselMode
        ) {
            _self.jumpToHighlightPage();
        }
    };

    /**
     * Slide back a page
     */
    common.Carousel.prototype.slideToPagePrevious = function () {
        const _self = this;

        if (_self.pageIndex > 0) {
            _self.pageIndex--;
            _self.setSliderPosition();
        }
    };

    /**
     * Slide forward a page
     */
    common.Carousel.prototype.slideToPageNext = function () {
        const _self = this;

        if (_self.pageIndex < _self.numPages - 1) {
            _self.pageIndex++;
            _self.setSliderPosition();
        }
    };

    /**
     * Scroll forward one tile by increasing the offsetLeft
     */
    common.Carousel.prototype.moveOffsetNext = function () {
        const _self = this;

        _self.slideContainer.scrollBy({
            left: _self.itemWidth + _self.gutterWidth,
            behavior: 'smooth'
        });
        _self.checkScrollPosition();
    };

    /**
     * Scroll backward one tile by increasing the offsetRight
     */
    common.Carousel.prototype.moveOffsetPrevious = function () {
        const _self = this;

        _self.slideContainer.scrollBy({
            left: (_self.itemWidth + _self.gutterWidth) * -1,
            behavior: 'smooth'
        });
        _self.checkScrollPosition();
    };

    /**
     * Checks whether the container is scrolled to the end,
     * if it is we disable the button and if not we enable it.
     */
    common.Carousel.prototype.checkScrollPosition = function () {
        const _self = this;

        setTimeout(() => {
            _self.isFirstPage = !_self.slideContainer.scrollLeft;
            _self.isLastPage =
                _self.slideContainer.scrollLeft +
                    _self.slideContainer.offsetWidth >
                _self.slideContainer.scrollWidth - OFFSET_BUFFER;

            _self.isFirstPage
                ? (disable(_self.previousArrow),
                  disable(_self.mobilePreviousButton))
                : (enable(_self.previousArrow),
                  enable(_self.mobilePreviousButton));
            _self.isLastPage
                ? (disable(_self.nextArrow), disable(_self.mobileNextButton))
                : (enable(_self.nextArrow), enable(_self.mobileNextButton));
        }, HALF_SECOND);
    };

    /**
     * Slide to a given page index
     *
     * @param {Integer} index - page index
     */
    common.Carousel.prototype.slideToPageIndex = function (index) {
        const _self = this;

        _self.pageIndex = Number(index);
        _self.setSliderPosition();
    };

    /**
     * Take current page index and calculate new slider position
     */
    common.Carousel.prototype.setSliderPosition = function () {
        const _self = this;

        _self.isFirstPage = _self.pageIndex === 0;
        _self.isLastPage = _self.pageIndex === _self.numPages - 1;

        const modulo = _self.numItems % _self.itemsPerPage;
        const lastPageItems = modulo ? modulo : _self.itemsPerPage;

        const regularPage =
            (_self.itemWidth + _self.gutterWidth) * _self.itemsPerPage;
        const finalPage = (_self.itemWidth + _self.gutterWidth) * lastPageItems;

        let slidePosition;

        if (!_self.isLastPage) {
            slidePosition = regularPage * _self.pageIndex;
        } else {
            // slide only the remaining items to avoid whitespace
            slidePosition = regularPage * (_self.pageIndex - 1) + finalPage;
        }

        // pass value to slide container to trigger slide
        _self.slideContainer.style.left = -slidePosition + 'px';

        // show/hide slide buttons when no longer relevant
        _self.isFirstPage
            ? disable(_self.previousArrow)
            : enable(_self.previousArrow);
        _self.isLastPage ? disable(_self.nextArrow) : enable(_self.nextArrow);

        _self.setActivePageItems();
    };

    /**
     * Set all items to active state
     */
    common.Carousel.prototype.setAllItemsActive = function () {
        const _self = this;

        Array.prototype.forEach.call(_self.items, function (item) {
            item.setAttribute('data-inactive', 'false');
        });

        _self.slideContainer.scrollLeft = 0;
        _self.slideContainer.scrollBy({
            left: (_self.itemWidth + _self.gutterWidth) * _self.pageIndex,
            behavior: 'smooth'
        });
        _self.checkScrollPosition();
    };

    /**
     * Set all items to inactive except those on the currently active page
     */
    common.Carousel.prototype.setActivePageItems = function () {
        const _self = this;

        if (_self.isLastPage) {
            Array.prototype.forEach.call(_self.items, function (item, i) {
                if (i < _self.items.length - _self.itemsPerPage) {
                    item.setAttribute('data-inactive', 'true');
                } else {
                    item.setAttribute('data-inactive', 'false');
                }
            });
            return;
        }

        Array.prototype.forEach.call(_self.items, function (item, i) {
            const itemIndex = _self.pageIndex * _self.itemsPerPage;
            const minIndex = itemIndex;
            const maxIndex = itemIndex + _self.itemsPerPage;

            if (i < minIndex || i >= maxIndex) {
                item.setAttribute('data-inactive', 'true');
            } else {
                item.setAttribute('data-inactive', 'false');
            }
        });
    };

    /**
     * Calculate the number of page items according to the current screen size
     *
     * @returns {Integer} - number of items to a page
     */
    common.Carousel.prototype.getItemsPerPage = function () {
        const _self = this;

        const desktopMax = '(max-width: ' + _self.breakpoint.desktop + 'px)';
        const desktopMin = '(min-width: ' + _self.breakpoint.desktop + 'px)';
        const tabletMax = '(max-width: ' + _self.breakpoint.tablet + 'px)';
        const tabletMin = '(min-width: ' + _self.breakpoint.tablet + 'px)';
        const phabletMax = '(max-width: ' + _self.breakpoint.phablet + 'px)';
        const phabletMin = '(min-width: ' + _self.breakpoint.phablet + 'px)';
        const mobileMax = '(max-width: ' + _self.breakpoint.mobile + 'px)';
        const mobileMin = '(min-width: ' + _self.breakpoint.mobile + 'px)';

        let itemsPerPage;

        if (window.matchMedia(desktopMin).matches) {
            itemsPerPage = _self.pageItems.wide;
        } else if (
            window.matchMedia(tabletMin).matches &&
            window.matchMedia(desktopMax).matches
        ) {
            itemsPerPage = _self.pageItems.desktop;
        } else if (
            window.matchMedia(phabletMin).matches &&
            window.matchMedia(tabletMax).matches
        ) {
            itemsPerPage = _self.pageItems.tablet;
        } else if (
            window.matchMedia(mobileMin).matches &&
            window.matchMedia(phabletMax).matches
        ) {
            itemsPerPage = _self.pageItems.phablet;
        } else if (window.matchMedia(mobileMax).matches) {
            itemsPerPage = _self.pageItems.mobile;
        }

        return itemsPerPage;
    };

    /**
     * Hide left and right navigation buttons, as well as pagination
     */
    common.Carousel.prototype.hideControls = function () {
        const _self = this;

        core.style.addClass(_self.previousArrow, HIDE_CLASS);
        core.style.addClass(_self.nextArrow, HIDE_CLASS);

        if (_self.pagination) {
            core.style.addClass(_self.pagination, HIDE_CLASS);
        }
    };

    /**
     * Show left and right navigation buttons, as well as pagination
     */
    common.Carousel.prototype.showControls = function () {
        const _self = this;

        core.style.removeClass(_self.previousArrow, HIDE_CLASS);
        core.style.removeClass(_self.nextArrow, HIDE_CLASS);

        if (_self.pagination) {
            core.style.removeClass(_self.pagination, HIDE_CLASS);
        }
    };

    common.Carousel.prototype.jumpToHighlightPage = function () {
        const _self = this;

        let highlightPageIndex = 0;

        if (_self.highlightIndex) {
            highlightPageIndex = Math.floor(
                _self.highlightIndex / _self.itemsPerPage
            );
        }

        _self.slideToPageIndex(highlightPageIndex);
    };
})(PULSE.app, PULSE.app.common, PULSE.core);
