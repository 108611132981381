(function (common) {
    /**
     * Gets media query value based on named breakpoints
     *
     * @param {string} breakpoint - breakpoint name or custom media query
     * @returns {string} media query string
     */
    common.getMediaQuery = function getMediaQuery(breakpoint) {
        let mediaQuery = '';

        switch (breakpoint) {
            case 'mobile':
                mediaQuery = '(max-width: 400px)';
                break;
            case 'mobileLarge':
                mediaQuery = '(max-width: 500px)';
                break;
            case 'phablet':
                mediaQuery = '(max-width: 640px)';
                break;
            case 'tablet':
                mediaQuery = '(max-width: 840px)';
                break;
            case 'desktop':
                mediaQuery = '(max-width: 1024px)';
                break;
            case 'wide':
                mediaQuery = '(max-width: 1300px)';
                break;
            case 'wider':
                mediaQuery = '(min-width: 1301px)';
                break;
            default:
                mediaQuery = breakpoint;
        }

        return mediaQuery;
    };
})(PULSE.app.common);
