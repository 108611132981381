(function (app, core, common) {
    common.image = {};

    /**
     * Will return the appropriate variant from a photo, given a tag
     *
     * @param {Array<Variant>} variants - array of variants
     * @param {string} tagLabel - the variant tag label to match against
     * @returns {Variant} variant
     */
    common.image.getVariantByTag = function (variants, tagLabel) {
        for (let i = 0; i < variants.length; i++) {
            if (variants[i].tag.label === tagLabel) {
                return variants[i];
            }
        }
        return null;
    };

    /**
     * Retrieves the ODIR-friendly variant URL
     *
     * @param  {PhotoResponse} image  - the image to retrieve the URL from, as it comes from the API (requires "onDemandUrl" to exist under the root)
     * @param  {number}        height - optional, the desired height
     * @param  {number}        width  - optional, the desired height
     * @returns {string}               - the variant URL
     */
    common.image.getOnDemandImageUrl = function getOnDemandImageUrl(
        image,
        height,
        width
    ) {
        if (image && image.onDemandUrl) {
            let properties = [];
            if (height) {
                properties.push(`height=${height}`);
            }
            if (width) {
                properties.push(`width=${width}`);
            }

            if (properties.length > 0) {
                return `${image.onDemandUrl}?${properties.join('&')}`;
            }

            return `${image.onDemandUrl}?width=500`;
        }
    };

    /**
     * using an array of variants return a variant object with the closest matching width
     *
     * @param {Array} variants an array of variant objects ( from cms api )
     * @param {int} width desired width
     * @returns {object} variant object that most closely matches the desired width
     */
    common.image.getVariantWithWidth = function (variants = [], width = 0) {
        let diff = Infinity;
        let nearest = false;

        variants.forEach((variant, i) => {
            const candidateDiff = Math.abs(variant.width - width);
            if (candidateDiff < diff) {
                diff = candidateDiff;
                nearest = i;
            }
        });

        return variants[nearest];
    };

    /**
     * Given a player photo and a desired set size, it returns the media query config that can be
     * applied to this photo. The set size has a default media query, but we need to check that the
     * original player photo's size works with this. If not, multipliers are removed and the base
     * width/height of the media query config is adjusted to ensure that the photo is never blown up
     * past its maximum size.
     *
     * @param  {object} playerPhoto        - API photo response
     * @param  {object} idealMediaQuerySet - media query set to ideally use
     * @returns {object}             - the Media Query Config appropriate for the photo
     */
    common.image.getMediaQuerySet = (playerPhoto, idealMediaQuerySet) => {
        const photoWidth = playerPhoto.originalDetails.width;
        const photoHeight = playerPhoto.originalDetails.height;

        const mediaQueryConfig = idealMediaQuerySet.map((query) => {
            const queryWidth = query.size.width;
            const queryHeight = query.size.height;

            let validQuery = { ...query };
            if (typeof queryWidth !== 'undefined') {
                let multipliers = _getValidMultipliers(
                    photoWidth,
                    queryWidth,
                    query.multipliers
                );
                if (multipliers.length === 0) {
                    validQuery.multipliers = [1];
                    validQuery.size.width = photoWidth;
                } else {
                    validQuery.multipliers = multipliers;
                }
            }
            if (typeof queryHeight !== 'undefined') {
                let multipliers = _getValidMultipliers(
                    photoHeight,
                    queryHeight,
                    query.multipliers
                );
                if (multipliers.length === 0) {
                    validQuery.multipliers = [1];
                    validQuery.size.height = photoHeight;
                } else if (validQuery.multipliers.length > multipliers.length) {
                    validQuery.multipliers = multipliers;
                }
            }
            return validQuery;
        });

        return mediaQueryConfig;
    };

    /**
     * Return an array of multipliers that don't make the desired dimension
     * go over the size of the original photo
     *
     * @param  {number} photoDimension   - the actual dimension of the photo
     * @param  {number} desiredDimension - the ideal (base) dimension (before multipliers are applied)
     * @param  {number[]} multipliers    - the multipliers
     * @returns {number[]}                - the multipliers that can be added
     */
    const _getValidMultipliers = (
        photoDimension,
        desiredDimension,
        multipliers
    ) => {
        return multipliers.filter(
            (multiplier) => desiredDimension * multiplier <= photoDimension
        );
    };
    /**
     * Returns image url of specified dimensions
     * Default values declared as undefined so queryString ignores param entirely if not provided
     *
     * @param {string} onDemandUrl - base url of on demand image
     * @param {string} width - width (pixels)
     * @param {string} height - height (pixels)
     *
     * @returns {string} The on demand image url with specified dimensions
     */

    common.image.getImageByDimensions = (
        onDemandUrl,
        width = undefined,
        height = undefined
    ) => {
        // eslint-disable-line no-undefined

        if (!width && !height) {
            console.warn('At least one of "width" and "height" is required.');
        }

        const paramsMap = {
            width: width,
            height: height
        };

        const queryString = core.url.buildQueryString(paramsMap, true);

        return onDemandUrl + queryString;
    };
})(PULSE.app, PULSE.core, PULSE.app.common);
