(function (app, core, common) {
    'use strict';

    const FIVE_HUNDRED_MS = 500;

    /**
     * @namespace app.VideoModal
     */

    /**
     * common.Modal
     * Handles Registration modal
     *
     * @param {DOMelement} element -
     */
    common.Modal = function (element) {
        const _self = this;

        _self.element = element;
        _self.modal = document.querySelector('.js-modal') || '';
        _self.isRegistrationModal = core.style.hasClass(
            _self.modal,
            'registration-modal'
        );

        if (_self.modal) {
            _self.setListeners();
        }

        if (_self.isRegistrationModal) {
            _self.openRegModalOnPageLoad();
        }
    };

    /**
     * Opens registration modal if url includes #regitration-modal
     */
    common.Modal.prototype.openRegModalOnPageLoad = function () {
        const _self = this;

        if (window.location.hash) {
            if (window.location.hash.substring(1) === 'registration-modal') {
                core.style.addClass(document.body, 'modal-is-active');
                core.style.addClass(_self.element, 'is-open');
                core.style.addClass(_self.modal, 'active');
            }
        }
    };

    /**
     * Listens for clicks on the modal
     */
    common.Modal.prototype.setListeners = function () {
        const _self = this;

        const closeButton = document.querySelector('.js-close-modal');

        _self.element.addEventListener('click', () => {
            if (_self.isRegistrationModal) {
                window.location.hash = '#registration-modal';
            }
            core.style.addClass(document.body, 'modal-is-active');
            core.style.addClass(_self.element, 'is-open');
            core.style.addClass(_self.modal, 'active');
        });

        closeButton.addEventListener('click', () => {
            if (_self.isRegistrationModal) {
                const cleanedUrl = window.location.href.split('#')[0];
                window.history.replaceState(null, null, cleanedUrl);
            }
            core.style.addClass(_self.modal, 'is-closing');
            setTimeout(() => {
                core.style.removeClass(document.body, 'modal-is-active');
                core.style.removeClass(_self.element, 'is-open');
                core.style.removeClass(_self.modal, 'active');
                core.style.removeClass(_self.modal, 'is-closing');
            }, FIVE_HUNDRED_MS);
        });
    };

    let widgets = document.querySelectorAll('[data-widget="modal"]');
    Array.prototype.forEach.call(widgets, (widget) => new common.Modal(widget));
})(PULSE.app, PULSE.core, PULSE.app.common);
