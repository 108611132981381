(function (app, common, core) {
    'use strict';

    const MOBILE_NAV = '.js-mobile-nav';
    const MOBILE_NAV_BUTTON = 'js-mobile-nav-btn';
    const DROPDOWN_BUTTON = 'js-dropdown-btn';
    const DROPDOWN_OPEN_CLASS = 'dropdown-is-open';
    const OPEN_CLASS = 'is-open';
    const ACTIVE_CLASS = 'is-active';

    app.MobileNavigation = function (container) {
        const _self = this;
        _self.container = container;

        _self.list = container.querySelector(MOBILE_NAV);
        _self.button = container.querySelector(`.${MOBILE_NAV_BUTTON}`);

        // common.addAriaClickListener( _self.button, _self.toggleMenu.bind( _self ) );
        common.addAriaClickListener(
            _self.container,
            _self.delegateEvent.bind(_self)
        );
    };

    app.MobileNavigation.prototype.delegateEvent = function delegateEvent(
        event
    ) {
        const _self = this;

        if (event.target.classList.contains(MOBILE_NAV_BUTTON)) {
            _self.toggleMenu();
        }

        if (event.target.classList.contains(DROPDOWN_BUTTON)) {
            let openDropdowns = _self.list.querySelectorAll(
                `.${DROPDOWN_BUTTON} + .${OPEN_CLASS}`
            );
            [].slice.call(openDropdowns, _self.toggleDropdown);
            _self.toggleDropdown(event.target.nextElementSibling);
        }
    };

    app.MobileNavigation.prototype.toggleMenu = function toggleMenu() {
        const _self = this;
        core.style.toggleClass(_self.list, OPEN_CLASS);
        core.style.toggleClass(_self.button, ACTIVE_CLASS);
    };

    app.MobileNavigation.prototype.toggleDropdown = function toggleDropdown(
        dropdown
    ) {
        const _self = this;

        core.style.toggleClass(dropdown, OPEN_CLASS);

        let openDropdowns = _self.list.querySelectorAll(
            `.${DROPDOWN_BUTTON} + .${OPEN_CLASS}`
        );
        if (!openDropdowns.length) {
            core.style.removeClass(_self.list, DROPDOWN_OPEN_CLASS);
            return;
        }

        core.style.addClass(_self.list, DROPDOWN_OPEN_CLASS);
    };

    app.widgetInitialiser.addMultipleWidgetsByName(
        'mobile-navigation',
        app.MobileNavigation
    );
})(PULSE.app, PULSE.app.common, PULSE.core);
