(function (app) {
    'use strict';

    const ITEM_SELECTOR = '.js-dynamic-child';
    const LIST_SELECTOR = '.js-dynamic-list';
    const BUCKET_SELECTOR = '.js-dynamic-more-bucket';
    const GHOST_CLASS = 'dynamic-more__ghost';
    const HIDE_CLASS = 'u-hide';
    const TEMPLATE = 'common.dynamic-more';

    /**
     * Dynamic More
     *
     * Add dynamic 'More' list item to linked-list components ( automatically
     * constructed when `linkedList` freemarker macro is passed
     * `dynamicMore=true` argument.
     *
     * @param { HTMLElement } container - the element the widget is attached to.
     * @param template
     */
    app.DynamicMore = function (container, template = null) {
        const _self = this;

        _self.container = container;
        _self.list = container.querySelector(LIST_SELECTOR);
        _self.list.style.position = 'relative';
        _self.items = container.querySelectorAll(ITEM_SELECTOR);
        _self.more = null;
        _self.template = template || TEMPLATE;
        _self.bucket = scaffold.call(_self, _self.list);

        const config = {
            root: _self.list,
            rootMargin: `0px -${_self.more.clientWidth}px 0px 0px`,
            threshold: 1
        };
        const observer = new IntersectionObserver(handler.bind(_self), config);
        _self.items.forEach((item) => observer.observe(item));
    };

    /**
     * Handler
     *
     * Is the intersection observer callback. Will determine items that are
     * entering/exiting the viewport and execute logic accordingly.
     *
     * @param { Array } items - array of observed items that have changed.
     */
    const handler = function handler(items) {
        if (!items[0].rootBounds.right) {
            return;
        }

        const _self = this;

        const sortedItems = items.reduce(groupItems, {
            exiting: [],
            entering: []
        });

        sortedItems.exiting.forEach(exit.bind(_self));
        sortedItems.entering.forEach(enter.bind(_self));

        const children = _self.bucket.children.length;
        if (children) {
            _self.more.classList.remove(HIDE_CLASS);
        } else {
            _self.more.classList.add(HIDE_CLASS);
        }
    };

    /**
     * Scaffold
     *
     * Will scaffold the DOM, creating the `more` `linked-list__item`.
     *
     * @param { HTMLElement } container - the container we want to append the `more` item to.
     * @returns { HTMLElement } the element selected by `BUCKET_SELECTOR`.
     */
    const scaffold = function scaffold(container) {
        const _self = this;
        const element = app.templating.render({}, _self.template, true);
        _self.more = container.appendChild(element);
        return container.querySelector(BUCKET_SELECTOR);
    };

    /**
     * Group Items
     *
     * Should be used in a `reduce`. Job is to group items into `exiting` and
     * `entering` objects.
     *
     * @param {object} collection - the reduced collection.
     * @param {object} collection.exiting - the items that are exiting the container
     * @param {object} collection.entering - the items that are entering the container
     * @param {object} item - the observed (intersection observer) item
     * @returns {object} collection - the reduced collection
     */
    const groupItems = (collection, item) => {
        if (item.boundingClientRect.right >= item.rootBounds.right) {
            collection.exiting.push(item);
        } else {
            collection.entering.push(item);
        }

        return collection;
    };

    /**
     * Exit
     *
     * Called on item that is exiting viewport.
     *
     * @param {object} item - the observed item.
     */
    const exit = function exit(item) {
        const _self = this;

        if (getComputedStyle(item.target, null).display === 'none') {
            return;
        }

        item.target.setAttribute('data-cloned', Date.now());
        const clone = item.target.cloneNode(true);
        _self.bucket.appendChild(clone);

        item.target.classList.add(GHOST_CLASS);
        item.target.style.left = `${
            item.boundingClientRect.left - item.rootBounds.left
        }px`;
    };

    /**
     * Enter
     *
     * Called on item that is entering viewport.
     *
     * @param {object} item - the observed item.
     */
    const enter = function enter(item) {
        const _self = this;
        const clone = _self.bucket.querySelector(
            `[data-cloned="${item.target.getAttribute('data-cloned')}"]`
        );

        if (!clone) {
            return;
        }

        clone.remove();
        item.target.classList.remove(GHOST_CLASS);
        item.target.removeAttribute('data-cloned');
        item.target.style.left = '0';
    };

    app.widgetInitialiser.addMultipleWidgetsByName(
        'dynamic-more',
        app.DynamicMore
    );
})(PULSE.app);
