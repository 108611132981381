(function (common) {
    'use strict';

    /**
     * @module TemplateHelpers
     *
     * A combination of utilties to use when templating, these are generally formatters
     * @type {object}
     */
    common.template = {};

    /**
     * Stringifies & escapes strings so they can be put into HTML
     *
     * @param  {string} string the string to clean
     * @returns {string}        output
     */
    common.template.cleanString = function (string) {
        return _.escape(JSON.stringify(string || ''));
    };

    /**
     * Prints a number with commas
     *
     * @param  {number} number Number value to print with commas as thousands
     * @returns {string}        Converted value with commas
     */
    common.template.getNumberWithCommas = function (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    /**
     * Given a single source variant config, return template-friendly strings
     *
     * @param  {MediaQueryConfig} conf - the media query config for a single source set
     * @returns {object}                - the srcset and media query, in string format
     */
    common.template.getSourceConfig = function getSourceConfig(conf) {
        let srcset = '';
        if (conf.variantUrls.length > 1) {
            srcset = `${conf.variantUrls.join(', ')} 2x`;
        } else {
            srcset = conf.variantUrls.join('');
        }
        let mediaQuery = [];
        if (typeof conf.minWidth !== 'undefined') {
            mediaQuery.push(`(min-width: ${conf.minWidth}px)`);
        }
        if (typeof conf.maxWidth !== 'undefined') {
            mediaQuery.push(`(max-width: ${conf.maxWidth}px)`);
        }
        return {
            srcset,
            mediaQuery: mediaQuery.join(' and ')
        };
    };

    /**
     * Add markup around pipes in a string
     *
     * @param  {string} string - the string to format
     * @returns {string}        - the output
     */
    common.template.formatTitle = function formatTitle(string) {
        let newString = string;

        // if there are no delimiters, make the 1st word strong
        if (newString.indexOf('|') === -1) {
            let newStringArray = newString.split(' ');
            newStringArray[0] = `<strong>${newStringArray[0]}</strong>`;
            return newStringArray.join(' ');
        }

        // if there's only one delimiter, add a 2nd
        // eslint-disable-next-line no-magic-numbers
        if (newString.split('|').length % 2 === 0) {
            newString += '|';
        }

        const regexp = /(\|)([^|]+)(\|)/g;
        const matches = string.match(regexp);
        if (matches) {
            matches.forEach((match) => {
                let highlight = `<strong>${match.substring(
                    1,
                    match.length - 1
                )}</strong>`;
                newString = newString.split(match).join(highlight);
            });
        }
        return newString;
    };

    /**
     * Helper for pluralisation of nouns
     *
     * @param {number} number - the number to base the logic off of
     * @param {string} singular - the singular version of the noun
     * @param {string} plural - the plural version of the noun
     * @param {boolean} includeNumber - whether to prepend the number to the output string or not
     * @returns {string} the pluralized string
     */
    common.template.pluralise = function (
        number,
        singular,
        plural,
        includeNumber
    ) {
        let string = includeNumber ? number + ' ' : '';
        return string + (number.toString() === '1' ? singular : plural);
    };

    /**
     * Separates the characters of a score for styling purposes#
     *
     * @param {string} string - the string to separate the characters of
     * @param {string} className - class to add to the return html
     * @returns {string} html - the split characters ht,l that is created.
     */
    common.template.separateCharacters = function (string, className) {
        let html = '';
        if (typeof string !== 'undefined') {
            let characterArray = string.toString().split('');
            let cls = className || '';

            for (let i = 0; i < characterArray.length; i++) {
                html +=
                    '<span class="' +
                    cls +
                    '">' +
                    characterArray[i] +
                    '</span>';
            }
        }

        return html;
    };

    /**
     * Seo Title
     *
     * Formats a title and aria-label attribute ready to be output in the markup,
     * removing any ".
     *
     * @param {string} title - The title to parse
     * @returns {string} - The formated attributes with the parsed title
     */
    common.template.seoTitle = function (title = '') {
        if (title) {
            const escapedTitle = title.replace('"', '');
            return `title="${escapedTitle}" aria-label="${escapedTitle}"`;
        }

        return title;
    };
})(PULSE.app.common);
