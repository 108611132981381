(function (common, core) {
    /**
     * To be fired when a modal is opened/closed and applies fixed to the body
     *
     * @param {boolean} modalOpening - true if modal is opening
     * @param {Integer} delay - time delay (millis) to apply u-body-no-scroll class
     * @param {boolean} isFixed - true if we want fixed style on body
     */
    common.bodyNoScrollFixed = function (
        modalOpening = false,
        delay = 0,
        isFixed = true
    ) {
        let noScrollCls = '';

        if (isFixed) {
            noScrollCls = 'u-body-no-scroll-fixed';
        } else {
            noScrollCls = 'u-body-no-scroll';
        }

        if (modalOpening) {
            common.scrollPos = window.pageYOffset;
            setTimeout(function () {
                core.style.addClass(document.body, noScrollCls);
            }, delay);
        } else {
            core.style.removeClass(document.body, noScrollCls);
            window.scrollTo(0, common.scrollPos);
        }
    };
})(PULSE.app.common, PULSE.core);
