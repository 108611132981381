(function (app, core) {
    'use strict';

    const OBSERVER_CONFIG = {
        root: null, // default to browser viewport
        rootMargin: '0px',
        threshold: 0.025
    };

    const OBSERVABLE_SELECTOR = '.js-lazy-load';

    /**
     * Class that loads in images when they become active
     * upon entering the viewport
     *
     * @param {HTMLElement} container the container element of the items that should be observed
     * @class
     */
    app.LazyLoad = function (container) {
        const _self = this;
        const observableContainers = [].slice.call(
            container.querySelectorAll(OBSERVABLE_SELECTOR)
        );

        // get items unobserved and apply the listeners
        const uninitiatedObservables =
            _self.getUnobservedElements(observableContainers);
        _self.setObservableListener(uninitiatedObservables);
    };

    /**
     * Filters the observableContainer list so the items being observed have not been observed before
     * and are the correct markup elements for observing
     *
     * @param {HTMLElement[]} observableContainers - the matching elements that are meant to be observed
     * @returns {Array} Filtered array of observable elements
     */
    app.LazyLoad.prototype.getUnobservedElements =
        function getUnobservedElements(observableContainers) {
            return observableContainers.filter((observableEl) => {
                return (
                    observableEl &&
                    observableEl.getAttribute('data-picture-in-view') !== 'true'
                );
            });
        };

    /**
     * Listens for elements to become visible within the viewport
     *
     * @param {HTMLElement[]} observableContainers - the matching elements to apply observer listeners to
     */
    app.LazyLoad.prototype.setObservableListener =
        function setOberservableListener(observableContainers) {
            const _self = this;

            _self.observer = new IntersectionObserver(
                _onActivate,
                OBSERVER_CONFIG
            );
            observableContainers.forEach((element) => {
                _self.observer.observe(element);
            });
        };

    /**
     * Callback function from intersection observer
     *
     * `getAttribute` has been used deliberately as it tested faster than
     * `element.dataset.attrName`.
     *
     * @param  {object[]} entries An Array of IntersectionObserver elements
     */
    const _onActivate = (entries) => {
        entries.forEach((contentItem) => {
            let imgContainer = contentItem.target;
            if (!imgContainer) {
                return;
            }

            // check if the intersection observer's already run on this element
            let hasIntersectedBefore = imgContainer.getAttribute(
                'data-picture-in-view'
            );
            if (
                contentItem.isIntersecting === false ||
                hasIntersectedBefore === 'true'
            ) {
                return;
            }

            // Picture is now in view so add this attr to stop it rendering again.
            imgContainer.setAttribute('data-picture-in-view', 'true');

            // get the picture element/image markup from the comment and add it to the container
            const imageComment = _getFirstCommentNodeChild(imgContainer);
            if (imageComment) {
                imgContainer.insertAdjacentHTML(
                    'beforeend',
                    imageComment.textContent.trim()
                );
            }

            // fade in the image (if needed - only js-faded-image marked elements need this)
            let fadedImage = imgContainer.querySelector('.js-faded-image');
            if (fadedImage) {
                if (fadedImage.complete) {
                    _fadeElementIn(fadedImage, imgContainer);
                } else {
                    // only add load listener if the image hasn't been loaded already
                    fadedImage.addEventListener('load', function () {
                        _fadeElementIn(fadedImage, imgContainer);
                    });
                }
            }
        });
    };

    const _fadeElementIn = function _fadeElementIn(fadedImage, imgContainer) {
        core.style.addClass(fadedImage, 'is-loaded');
        core.style.addClass(imgContainer, 'is-loaded');
    };

    /**
     * Returns the first comment node child of an element
     *
     * @param  {HTMLElement} element - the html element to search the child nodes for
     * @returns {(Comment|null)}      - the 1st comment node found, or null
     */
    const _getFirstCommentNodeChild = function _getFirstCommentNodeChild(
        element
    ) {
        for (let i = 0; i < element.childNodes.length; i++) {
            if (element.childNodes[i].nodeType === Node.COMMENT_NODE) {
                return element.childNodes[i];
            }
        }
        return null;
    };

    app.widgetInitialiser.addMultipleWidgetsByName(
        'lazy-load-images',
        app.LazyLoad
    );
})(PULSE.app, PULSE.core);
