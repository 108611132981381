(function (app, core, common) {
    'use strict';

    const STATUS = {
        UPCOMING: 'U',
        LIVE: 'L',
        FIRST_HALF: 'L1',
        SECOND_HALF: 'L2',
        OVERTIME_FIRST_HALF: 'L3',
        OVERTIME_SECOND_HALF: 'L4',
        HALF_TIME: 'LHT',
        FULL_TIME: 'LFT',
        COMPLETE: 'C',
        POSTPONED: 'UP'
    }

    /**
     * Get type class from a match event description
     *
     * @param {string} matchEvent - Description Describition of matchEvent
     * @returns {string} A class representing the description
     */
    common.getEventTypeClass = function (matchEvent) {
        switch (matchEvent.type) {
            case 'PS':
                return 'time-w';
            case 'PE':
                if (matchEvent.phase === '1') {
                    return 'time-half-w';
                }
                return 'time-full-w';
            default:
            //Do something...
        }

        switch (matchEvent.description) {
            // Red Card
            case 'R':
                return 'icn-red-card';
            // Second Yellow Card ( Red Card )
            case 'YR':
                return 'icn-card-yellow-red';
            // Own Goal
            case 'O':
                return 'icn-og';
            // Goal
            // Penalty
            case 'G':
            case 'P':
                return 'icn-ball';
            default:
                return '';
        }
    };

    /**
     * Get class for match event
     *
     * @param {object} matchEvent Match event
     * @returns {string} cssClass Class for matchEvent
     */
    common.getEventLabel = function (matchEvent) {
        switch (matchEvent.type) {
            case 'PS':
                if (matchEvent.phase === '1') {
                    return 'Kick Off';
                }
                return 'Second Half';
            case 'PE':
                if (matchEvent.phase === '1') {
                    return 'Half Time';
                }
                return 'Full Time';
            default:
            // Do something
        }
        switch (matchEvent.description) {
            case 'G':
            case 'P':
                return 'Goal';
            case 'O':
                return 'Own Goal';
            case 'Y':
                return 'Yellow Card';
            case 'R':
            case 'YR':
                return 'Red Card';
            case 'S':
            case 'M':
                return 'Pen. Missed';
            case 'sub':
            case 'OFF':
            case 'ON':
                return 'Substitution';
            default:
                return '';
        }
    };


    common.openMatchInUrl = function ( url, container, isKnockoutPage=false ) {
        const urlParams = new URLSearchParams( url );
        const matchIdParam = urlParams.get( 'matchId' ) || '';
        const tabParam = urlParams.get( 'tab' ) || '';
        const teamParam = urlParams.get( 'team' ) || '';  
    
        let events = {
            renderedCards: [],
            renderedSubs: [],
            renderedScoring: [],
            renderedEvents: []
        };

        if ( matchIdParam ) {
            events = new app.MatchModal( matchIdParam, container, events, isKnockoutPage, tabParam, teamParam );
        }

        return events;
    }

    /**
     * TShow/Hides live match and cup finals CTAs
     *
     * @param  {HTMLElement} element Widget container
     * @param  {Integer} liveId Match ID of live match
     */
    common.setFixtureCta = function ( container ) {
        const _self = this;

        const fixtureModal = document.querySelector('.js-fixture-modal');
        const cupFinalCta = document.querySelector('.js-cup-final-cta');
        const liveMatchCta = document.querySelector('.js-live-cta');
        const completed = container.dataset.completed || false;
        const live = container.dataset.live || false;

        if ( fixtureModal && cupFinalCta && liveMatchCta ) {
            const modalIsOpen = core.style.hasClass( fixtureModal, 'isVisible' );
            if ( completed === "true" && !modalIsOpen ) {
                core.style.addClass( liveMatchCta, 'u-hide' );
                core.style.removeClass( cupFinalCta, 'u-hide' );
            } else if ( live === "true" && !modalIsOpen ) {
                core.style.addClass( cupFinalCta, 'u-hide' );
                core.style.removeClass( liveMatchCta, 'u-hide' );
            } else if ( live === "false" && completed === "false" || modalIsOpen  ) {
                core.style.addClass( cupFinalCta, 'u-hide' );
                core.style.addClass( liveMatchCta, 'u-hide' );
            }
        }
    };

    /**
     * Updates match status and set liveMatch class
     *
     * @param  {HTMLElement} currentItem Match info container
     * @param  {object} newDataItem Match object
     * @param  {HTMLElement} scores Match score container
     * @param {Boolean} isModal Updating match modal or fixture list
     */
    common.updateStatus = function ( currentItem, newDataItem, scores, isModal=false ) {
        const _self = this;

        let htScore = '';
        if ( isModal ) {
            htScore = currentItem.querySelector( '.js-ht-scores' );
        } else {
            currentItem.dataset.status = newDataItem.status;
        }
        let liveId = '';
        const statusElem = currentItem.querySelector( '.js-status' );
        let statusLabel = moment( newDataItem.time.label ).format('h:mm A') + '*'  || '';

        if ( newDataItem.status === STATUS.COMPLETE ) {
            statusLabel = 'label.rugby.matchstatus.fulltime.long';
        } else if ( newDataItem.status.startsWith('L') ) {
            if ( newDataItem.status === STATUS.FIRST_HALF ) { 
                statusLabel = 'label.firsthalf';
            } else if ( newDataItem.status === STATUS.SECOND_HALF ) {
                statusLabel = 'label.secondhalf';
            } else if ( newDataItem.status === STATUS.OVERTIME_FIRST_HALF ||  newDataItem.status === STATUS.OVERTIME_SECOND_HALF ) {
                statusLabel = 'label.rugby.overtime';
            } else if ( newDataItem.status === STATUS.HALF_TIME ) {
                statusLabel = 'label.rugby.matchstatus.halftime.long';
            } else if ( newDataItem.status === STATUS.FULL_TIME ) {
                statusLabel = 'label.svns.fulltime';
            }
            liveId = 'liveMatch';
        } else if ( newDataItem.status === STATUS.POSTPONED ) {
            statusLabel = 'label.matchstatus.postponed';
        }

        if ( statusElem ) {
            statusElem.innerHTML = PULSE.I18N.lookup( statusLabel );
            if ( !isModal ) {
                statusElem.setAttribute( 'id', liveId );
            } else if ( htScore ) {
                common.toggleLiveClass( htScore, liveId );
            }
            common.toggleLiveClass( statusElem, liveId );
        }

        if ( liveId === 'liveMatch' && !core.style.hasClass( scores, 'liveMatch' ) ) {
            core.style.addClass( scores, 'liveMatch' );
        } else if ( liveId !== 'liveMatch' && core.style.hasClass( scores, 'liveMatch' ) ) {
            core.style.removeClass( scores, 'liveMatch' );
        }
    }

    /**
     * Toggles liveMatch class on and off, used to set live match styling
     *
     * @param  {HTMLElement} element Match info container
     * @param  {Integer} liveId Match ID of live match
     */
    common.toggleLiveClass = function ( elem, liveId ) {
        const _self = this;

        if ( core.style.hasClass( elem, 'liveMatch' ) && liveId !== 'liveMatch' ) {
            core.style.removeClass( elem, 'liveMatch' );
        } else if ( !core.style.hasClass( elem, 'liveMatch') && liveId === 'liveMatch' ) {
            core.style.addClass( elem, 'liveMatch' );
        }
    }

    /**
     * Returns the score at half-time in the match
     *
     * @param  {object} timeline The match timeline
     * @param  {string} matchStatus The status of the match
     * @returns {string} String representing the score at half-time or null if not applicable
     */
    common.getHalfTimeScores = ( timeline, matchStatus) => {

        // Scores are only shown after the first half
        let scores = null;

        // Check we're in the second half or later
        const afterFirstHalf = ['LT2', 'LHT', 'L2', 'L3', 'LFT', 'LXD', 'LSD', 'LK', 'C'];
        if (afterFirstHalf.includes(matchStatus)) {

            scores = [0, 0];

            const firstHalfPointsEvents = timeline.filter( event => event.points && event.phase === 'L1' );
            firstHalfPointsEvents.forEach( event => {
                const teamIndex = parseInt( event.teamIndex );
                scores[ teamIndex ] += parseInt( event.points );
            } );

            scores = scores.join( '-' );
        }

        return scores;
    };

    /**
     * Get team model
     *
     * @param {object} element - team object
     * @param {string} status - match status
     */
    common.getTeamModel = function (element, status) {
        let team = {};

        //return short name and fall back to full name if club object is not available
        if (element.team && element.team.club && element.team.club.shortName) {
            team.name = element.team.club.shortName;
        } else {
            team.name = element.team.name;
        }

        if (element.team && element.team.club) {
            //get abbreviation to render the club badge
            if (element.team.club.abbr) {
                team.abbr = element.team.club.abbr;
            } else if (element.team.altIds) {
                team.abbr = element.team.altIds.opta;
            } else {
                team.abbr = '';
            }
        }

        if (status === 'C') {
            team.score = element.score;
        }

        return team;
    };

    /* eslint-disable no-magic-numbers */
    common.getEventTime = function (matchEvent) {
        let time = Math.floor(matchEvent.clock.secs / 60);

        if (matchEvent.phase === '1' && time > 45) {
            time = '45 +' + (time - 45);
        } else if (matchEvent.phase === '2' && time > 90) {
            time = '90 +' + (time - 90);
        }
        return time + "'";
    };
    /* eslint-enable no-magic-numbers */

    /**
     * Add matchEvent to playerMap
     *
     * @param {object} map Map of players with event types
     * @param {object} matchEvent The match event to be added to the player map
     * @param {int} id The id of the player
     * @param {string} description Description of the event
     * @returns {object} map PlayerMap after event has been added
     */
    common.getPlayerForType = function (map, matchEvent, id, description) {
        let val = matchEvent.clock.secs;
        let label = common.getEventTime(matchEvent);
        if (!map[id]) {
            map[id] = {};
        }

        if (matchEvent.description !== 'Y') {
            if (matchEvent.description === 'P') {
                label += ' (pen)';
            } else if (matchEvent.description === 'O') {
                label += ' (og)';
            }
            if (!map[id][description]) {
                map[id][description] = [];
            }
            map[id][description].push({
                label: label,
                val: val
            });
        }
        return map;
    };

    /**
     * Create a map relating match events to each player
     *
     * @param {[object]} events List of match events
     * @returns {object} map PlayerMap Relating each player to a set of events
     */
    common.getPlayerEvents = function (events) {
        let map = {};
        if (events && events.length > 0) {
            events.forEach(function (matchEvent) {
                if (typeof matchEvent.personId !== 'undefined') {
                    let description =
                        matchEvent.description === 'P'
                            ? 'G'
                            : matchEvent.description;
                    common.getPlayerForType(
                        map,
                        matchEvent,
                        matchEvent.personId,
                        description
                    );
                }
                if (matchEvent.assistId) {
                    common.getPlayerForType(
                        map,
                        matchEvent,
                        matchEvent.assistId,
                        'AS'
                    );
                }
            });
        }
        return map;
    };

    /**
     * Find player object for a match event
     *
     * @param {[object]} teams List of teams in match
     * @param {int} playerId Id of player to find
     * @returns {object} Player object
     */
    common.getPlayerForEvent = function (teams, playerId) {
        for (let i = 0; i < teams.length; i++) {
            let team = teams[i];
            if (team.lineup) {
                for (let j = 0; j < team.lineup.length; j++) {
                    if (team.lineup[j].id === playerId) {
                        return team.lineup[j];
                    }
                }
            }
            if (team.substitutes) {
                for (let index = 0; index < team.substitutes.length; index++) {
                    if (team.substitutes[index].id === playerId) {
                        return team.substitutes[index];
                    }
                }
            }
        }
    };

    /**
     * Generate eventsMap per team, showing events for each team in chronological order, grouped by person
     *
     * @param {[object]} events List of events in match
     * @param {[object]} teams List of teams in match
     * @param {object} playerMap Map of players with their events
     * @returns {object} eventMap EventsMap for each team showing all the events
     */
    common.getTeamEventsMap = function (events, teams, playerMap) {
        let map = { matchEvents: [[], []], assists: [[], []] };
        if (events && events.length > 0) {
            events.forEach(function (matchEvent) {
                if (
                    typeof matchEvent.teamId !== 'undefined' &&
                    matchEvent.type &&
                    (matchEvent.description === 'G' ||
                        matchEvent.description === 'Y' ||
                        matchEvent.description === 'YR' ||
                        matchEvent.description === 'R' ||
                        matchEvent.description === 'P' ||
                        matchEvent.description === 'O')
                ) {
                    let typeClass = common.getEventTypeClass(matchEvent);
                    let description = matchEvent.description;
                    if (description === 'P') {
                        description = 'G';
                    }
                    let hasDescription =
                        playerMap &&
                        playerMap[matchEvent.personId] &&
                        playerMap[matchEvent.personId][description];
                    let matchClock = hasDescription
                        ? playerMap[matchEvent.personId][description].length >
                              0 &&
                          playerMap[matchEvent.personId][description][0].val ===
                              matchEvent.clock.secs
                        : false;
                    let descCheck = matchClock
                        ? !(
                              description === 'Y' &&
                              playerMap[matchEvent.personId].YR
                          )
                        : false;
                    if (descCheck) {
                        var index = 0;
                        if (teams[1].teamId === matchEvent.teamId) {
                            index = 1;
                        }

                        var player = common.getPlayerForEvent(
                            teams,
                            matchEvent.personId
                        );

                        if (player) {
                            if (
                                description === 'G' &&
                                playerMap[matchEvent.personId][description]
                                    .length > 1
                            ) {
                                typeClass = 'icn-ball';
                            }

                            var eventTimes =
                                ' ' +
                                playerMap[matchEvent.personId][description]
                                    .map(function (elements) {
                                        return elements.label;
                                    })
                                    .join(', ');
                            map.matchEvents[index].push({
                                teamId: matchEvent.teamId,
                                typeClass: typeClass,
                                playerName: player.name.display,
                                playerShortName:
                                    player.name.first.substring(0, 1) +
                                    '. ' +
                                    player.name.last,
                                playerUrl: common.generateUrl(
                                    'player',
                                    player.id,
                                    '',
                                    player.name.display.replace(
                                        new RegExp(' ', 'g'),
                                        '-'
                                    )
                                ),
                                eventTimes: eventTimes
                            });
                        }
                    }
                    let playerMapCheck =
                        playerMap &&
                        playerMap[matchEvent.assistId] &&
                        playerMap[matchEvent.assistId].AS &&
                        playerMap[matchEvent.assistId].AS.length > 0;
                    let playerClockCheck = playerMapCheck
                        ? playerMap[matchEvent.assistId].AS[0].val ===
                          matchEvent.clock.secs
                        : false;
                    if (
                        matchEvent.teamId &&
                        matchEvent.description === 'G' &&
                        matchEvent.assistId &&
                        playerClockCheck
                    ) {
                        index = 0;
                        if (teams[1].teamId === matchEvent.teamId) {
                            index = 1;
                        }

                        player = common.getPlayerForEvent(
                            teams,
                            matchEvent.assistId
                        );

                        if (player) {
                            eventTimes =
                                ' ' +
                                playerMap[matchEvent.assistId].AS.map(function (
                                    elements
                                ) {
                                    return elements.label;
                                }).join(',') +
                                ' ';
                            map.assists[index].push({
                                playerName: player.name.display,
                                playerUrl: common.generateUrl(
                                    'player',
                                    player.id,
                                    player.name.display.replace(
                                        new RegExp(' ', 'g'),
                                        '-'
                                    )
                                ),
                                eventTimes: eventTimes
                            });
                        }
                    }
                }
            });
        }
        return map;
    };

    common.match = {};

    /**
     * Gets the team abbreviation from a team object, if it cant be found will return TBC
     *
     * @param {object} [team={}] the team object
     * @returns {string} the tem abbreviation string
     */
    common.match.getTeamAbbreviation = function getTeamAbbreviation(team = {}) {
        if (team && team.club && team.club.abbr) {
            return team.club.abbr;
        }
        return PULSE.I18N.lookup('label.team.tbc');
    };

    /**
     * Generate a url for a match
     *
     * @param {object} [match={}] the match object from the api
     * @returns {string} the url
     */
    common.match.getMatchLink = function getMatchLink(match = {}) {
        return `/match/${match.id}`;
    };

    /**
     * Get the teams name or tbc if there is no name to be found
     *
     * @param {object} [team={}] the team object from the match
     * @returns {string} the team name or tbc
     */
    common.match.getTeamName = function getTeamName(team = {}) {
        if (team && team.shortName) {
            return team.shortName;
        }
        return PULSE.I18N.lookup('label.team.tbc');
    };

    /**
     * Format the match time or provisional time if available.
     *
     * @param {object} [match={}] the match object from the api
     * @param {string} [format="HH:mm"] the format to pass to moment
     * @returns {string} the formatted date
     */
    common.match.getFormattedMatchDateTime = function getFormattedMatchDateTime(
        match = {},
        format = 'HH:mm'
    ) {
        if (match.kickoff && match.kickoff.millis) {
            return moment(match.kickoff.millis).format(format);
        }

        if (match.provisionalKickoff && match.provisionalKickoff.millis) {
            return moment(match.provisionalKickoff).format(format);
        }

        return PULSE.I18N.lookup('label.time.tbc');
    };

    /**
     * Generate a string representing a penalty shootout
     *
     * @param {object} [match={}] the match object from the api
     * @param {number} homeTeamId the id of the home team
     * @returns {string} the penalty shootout score e.g. 0-0
     */
    common.match.getPenaltiesString = function getPenaltiesString(
        match = {},
        homeTeamId
    ) {
        const { shootout, penaltyShootouts } = match;

        if (!shootout || !penaltyShootouts.length) {
            return;
        }

        const penScores = [0, 0];

        penaltyShootouts.forEach((penalty) => {
            penScores[penalty.teamId === homeTeamId ? 0 : 1] = penalty.score;
        });

        return penScores.join(' - ');
    };

    /**
     * Generate a string representing the score on aggregate for a match
     *
     * @param {object} secondLeg the match object from the api, should be a second leg
     * @returns {string} the string of the score over two legs
     */
    common.match.getAggregateScore = function getAggregateScore(secondLeg) {
        if (
            !secondLeg ||
            !secondLeg.aggregateDetails ||
            !secondLeg.aggregateDetails.legs.length ||
            !(
                secondLeg.fixtureType ===
                common.constants.footballFixtureType.SECOND_LEG
            )
        ) {
            return;
        }

        const firstLeg = secondLeg.aggregateDetails.legs[0];

        const {
            0: { score: awayTeamScoreFirstLeg },
            1: { score: homeTeamScoreFirstLeg }
        } = firstLeg.teams;
        const {
            0: { score: homeTeamScoreSecondLeg },
            1: { score: awayTeamScoreSecondLeg }
        } = secondLeg.teams;

        return `${homeTeamScoreFirstLeg + homeTeamScoreSecondLeg} - ${
            awayTeamScoreFirstLeg + awayTeamScoreSecondLeg
        }`;
    };

    /**
     * Get a day timestamp for the start of a marticular day given as ISO timestamp
     *
     * @param {string} dateTime the ISO timestamp string
     * @returns {number} the unix timestamp
     */
    const getDayTimestamp = (dateTime) => {
        const date = moment(dateTime);
        const startOfDay = date.startOf('day');
        return startOfDay.unix();
    };

    /**
     * Get a day timestamp for the start of a marticular month given as ISO timestamp
     *
     * @param {string} dateTime the ISO timestamp string
     * @returns {number} the unix timestamp
     */
    const getMonthTimestamp = (dateTime) => {
        const date = moment(dateTime);
        const startOfMonth = date.startOf('month');
        return startOfMonth.unix();
    };

    common.match.groupFunctions = {
        byDay: (match) =>
            getDayTimestamp(common.match.getFormattedMatchDateTime(match, '')),
        byMonth: (match) =>
            getMonthTimestamp(common.match.getFormattedMatchDateTime(match, ''))
    };

    /**
     * When rendering we normally need to group the match objects by something. for example month or day.
     * This function will group the matches into a map and also supply akey ordering that you can use to
     * iterate through the maps keys in the right order.
     *
     * @param {Array.<object>} [matches=[]] the matchs object from the api
     * @param {Function} [groupFunction=common.match.groupFunctions.byMonth] a function that takes in a match and returns some value to group by
     * @param {string} [order=common.constants.footballOrderMapping.ASCENDING] the ordering that needs to be maintained
     * @returns {object} the grouped matches and an iteration order for keys.
     */
    common.match.groupMatches = function groupMatches(
        matches = [],
        groupFunction = common.match.groupFunctions.byMonth,
        order = common.constants.footballOrderMapping.ASCENDING
    ) {
        const result = {
            mapping: {},
            iterationOrder: []
        };

        result.mapping = matches.reduce((mapping, match) => {
            const groupVal = groupFunction(match);

            if (!mapping[groupVal]) {
                mapping[groupVal] = [];
            }

            mapping[groupVal].push(match);

            return mapping;
        }, {});

        const keys = Object.keys(result.mapping);

        switch (common.constants.footballOrderMapping[order]) {
            case common.constants.footballOrderMapping.ASCENDING:
                result.iterationOrder = keys.sort();
                break;
            default:
                result.iterationOrder = keys.sort().reverse();
        }

        return result;
    };

    /**
     * Generate a model for rendering a match
     *
     * @param {object} [match={}] the match object from the api
     * @returns {object} the model ready to be passed into the template
     */
    common.match.getRenderModel = function getRenderModel(match) {
        const {
            0: { team: homeTeam, score: homeTeamScore },
            1: { team: awayTeam, score: awayTeamScore }
        } = match.teams;
        const comp = match.gameweek.compSeason.competition;
        return {
            status: match.status,
            matchLink: common.match.getMatchLink(match),
            compAbbreviation: comp.abbreviation,
            ground: match.ground,
            clock: match.clock,
            homeTeamName: common.match.getTeamName(homeTeam),
            homeTeamAbbreviation: common.match.getTeamAbbreviation(homeTeam),
            awayTeamName: common.match.getTeamName(awayTeam),
            awayTeamAbbreviation: common.match.getTeamAbbreviation(awayTeam),
            homeTeamScore: homeTeamScore,
            awayTeamScore: awayTeamScore,
            formattedMatchTime: common.match.getFormattedMatchDateTime(
                match,
                'HH:mm'
            ),
            formattedMatchDateTime: common.match.getFormattedMatchDateTime(
                match,
                'DD MM YYYY'
            ),
            finalScoreModel: {
                penalties: common.match.getPenaltiesString(match, homeTeam.id),
                aggregateScore: common.match.getAggregateScore(match),
                extraTime: match.extraTime
            }
        };
    };
})(PULSE.app, PULSE.core, PULSE.app.common);
