(function (app, core, common) {
    const COPY = {
        button: '.js-copy-url',
        message: '.js-copy-message'
    };

    const EXPANDABLE = {
        toggle: '.js-social-expand',
        close: '.js-options-column-close',
        shareSvg: '.js-expand',
        closeSvg: '.js-close'
    };

    const CLASS = {
        open: 'is-open',
        hide: 'u-hide',
        active: 'is-active',
        copyFail: 'social-share__copy-message--fail'
    };

    /**
     * constructor for the page share widget. Widget requires following data attributes to be
     * present on target button elements;
     *
     * data-social - if this contains a url then it will be used as the page share url
     * data-social-service - the service name ( should correlate to an entry in the socialLinks
     * object that is defined in socialHelper Class - ../../js/social-helpers.js)
     *
     * @param {object} element element defining the page share widget
     * @param {string} url     URL to share
     * @class
     */
    common.SharePage = function (element, url) {
        const _self = this;

        _self.element = element;
        _self.render = _self.element.getAttribute('data-render');
        _self.bodyContent = _self.element.getAttribute('data-body');
        _self.defaultClosed = _self.element.getAttribute('data-closed');
        _self.socialShareList = _self.element.querySelector(
            '.js-social-share-list'
        );

        //copy url to clipboard
        _self.copyBtn = _self.element.querySelector(COPY.button);
        _self.copyMessage = _self.element.querySelector(COPY.message);

        _self.url = _self.element.getAttribute('data-link-to')
            ? window.location.hostname +
              _self.element.getAttribute('data-link-to')
            : url;

        //if the sharing button is expandable
        if (_self.defaultClosed) {
            _self.toggleButton = _self.element.querySelector(EXPANDABLE.toggle);
            _self.closeButton = _self.element.querySelector(EXPANDABLE.close);

            _self.shareSvg = _self.element.querySelector(EXPANDABLE.shareSvg);
            _self.closeSvg = _self.element.querySelector(EXPANDABLE.closeSvg);

            _self.setExpandableListeners();
        }

        _self.setListeners();
    };

    /**
     * Set Listeners for the expandable button
     */
    common.SharePage.prototype.setExpandableListeners = function () {
        const _self = this;

        common.addAriaClickListener(_self.toggleButton, function () {
            _self.element.classList.toggle(CLASS.open);

            if (_self.closeSvg && _self.shareSvg) {
                _self.closeSvg.classList.toggle(CLASS.hide);
                _self.shareSvg.classList.toggle(CLASS.hide);
            }
        });

        common.addAriaClickListener(_self.closeButton, function () {
            _self.element.classList.remove(CLASS.open);
        });
    };

    /**
     * start listening for click events on the element
     */
    common.SharePage.prototype.setListeners = function () {
        const _self = this;

        // bind clicks on individual social buttons
        common.addAriaClickListener(_self.element, (event) => {
            // handle clicks on individual social clicks
            const clicked = _self.getSocialDataset(event.target);

            core.style.addClass(_self.socialShareList, 'active');

            if (clicked && clicked.socialService) {
                app.socialHelpers[clicked.socialService].sharePage(
                    _self.url,
                    false,
                    _self.updateBodyContent()
                );
            }
        });

        if (document.queryCommandSupported('copy')) {
            common.addAriaClickListener(_self.copyBtn, () => _self.copyUrl());
        } else {
            _self.copyButton.classList.add(CLASS.hide);
        }

        document.addEventListener('click', function handleClickOutsideBox(evt) {
            if (!_self.element.contains(evt.target)) {
                core.style.removeClass(_self.socialShareList, 'active');
            }
        });
    };

    /**
     * Copy url to clipboard
     */
    common.SharePage.prototype.copyUrl = function () {
        const _self = this;
        const url = _self.url || window.location.href;

        // eslint-disable-next-line no-undef
        navigator.clipboard.writeText(url).then(
            () => _self.copyUrlMessage(),
            () => _self.copyUrlMessage(true)
        );
    };

    /**
     * Display a message that tells the user if the url has been copied to the clipboard
     * or not
     *
     * @param {boolean} fail - true if copying to the clipboard has failed
     */
    common.SharePage.prototype.copyUrlMessage = function (fail) {
        const _self = this;
        const delayTime = 2000;

        _self.copyBtn.classList.add(CLASS.active);

        if (fail) {
            _self.copyMessage.classList.add(CLASS.copyFail);
            _self.copyMessage.innerHTML = 'URL could not be copied';
        }

        setTimeout(function () {
            _self.copyBtn.classList.remove(CLASS.active);
        }, delayTime);
    };

    /**
     * move up the dom tree to find the element containing the desired data attributes. Do not traverse up past the
     * widget container. return the data set attribute of the element.
     *
     * @param {object} element DOM Element on which to begin the traversal
     * @returns {object} hash - dataset attribute of the element or fale if no element can be found
     */
    common.SharePage.prototype.getSocialDataset = function (element) {
        const _self = this;

        let inspecting = element;
        const checkThisElement = _self.element.getAttribute(
            'data-social-service'
        );

        if (checkThisElement) {
            return _self.element.dataset;
        }

        do {
            const thisAtt = inspecting.getAttribute('data-social-service');
            if (thisAtt) {
                return inspecting.dataset;
            }

            inspecting = inspecting.parentElement;
        } while (inspecting !== _self.element);

        return false;
    };

    /**
     * updates the bodyContent for already created listeners (in some cases we might want to change the
     * message to share without creating a new instance)
     *
     * @returns {string} watever contains the 'data-body' attribute
     */
    common.SharePage.prototype.updateBodyContent = function () {
        const _self = this;
        return _self.element.getAttribute('data-body');
    };
})(PULSE.app, PULSE.core, PULSE.app.common);
