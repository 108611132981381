(function (common) {
    'use strict';

    /**
     * Global Constants Object
     *
     * All global constants should be defined inside this object. A recommended
     * approach is to group constants that are specific to widgets under that
     * widget's constructor name.
     */
    if (typeof common.constants === 'undefined') {
        common.constants = {};
    }

    /**
     * All glboal InfiniteScrollWidget constants should be defined inside this
     * object.
     */
    common.constants.InfiniteScrollWidget = {
        READY: 'app.InfiniteScrollWidget.ready'
    };

    /**
     * All global Search constants should be defined inside this
     * object.
     */
    common.constants.Search = {
        SEARCH_FIRED: 'search.fired'
    };

    common.constants.time = (() => {
        const ONE_SECOND_IN_MS = 1000;
        const ONE_MINUTE_IN_SECS = 60;
        const ONE_HOUR_IN_MINS = 60;
        const ONE_DAY_IN_HOURS = 24;
        const HALF_SECOND_IN_MS = 500;

        const time = {
            oneDay:
                ONE_SECOND_IN_MS *
                ONE_MINUTE_IN_SECS *
                ONE_HOUR_IN_MINS *
                ONE_DAY_IN_HOURS,
            oneHour: ONE_SECOND_IN_MS * ONE_MINUTE_IN_SECS * ONE_HOUR_IN_MINS,
            oneMinute: ONE_SECOND_IN_MS * ONE_MINUTE_IN_SECS,
            oneSecond: ONE_SECOND_IN_MS,
            halfSecond: HALF_SECOND_IN_MS
        };

        return time;
    })();

    common.constants.footballMatchTimeline = {};

    common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS = {
        GOAL: 'G',
        YELLOW_CARD: 'Y',
        YELLOW_RED_CARD: 'YR',
        RED_CARD: 'R',
        OWN_GOAL: 'O',
        SUB_OFF: 'OFF',
        SUB_SIMPLE: 'sub',
        SUB_ON: 'ON',
        PENALTY: 'P',
        PENALTY_MISSED: 'M',
        PENALTY_SAVED: 'S'
    };

    common.constants.footballFixtureType = {
        REGULAR: 'REGULAR',
        FIRST_LEG: 'FIRST_LEG',
        SECOND_LEG: 'SECOND_LEG'
    };

    common.constants.footballDetailMapping = {
        BASIC: 0,
        STANDARD: 1,
        DETAILED: 2
    };

    common.constants.footballOrderMapping = {
        ASCENDING: 'asc',
        DESCENDING: 'desc'
    };

    common.constants.footballMatchTimeline.EVENT_LABELS = {
        [common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS.GOAL]:
            'label.timeline.event.goal',
        [common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS.YELLOW_CARD]:
            'label.timeline.event.yellowcard',
        [common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS
            .YELLOW_RED_CARD]: 'label.timeline.event.yellowred',
        [common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS.RED_CARD]:
            'label.timeline.event.redcard',
        [common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS.OWN_GOAL]:
            'label.timeline.event.owngoal',
        [common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS.SUB_OFF]:
            'label.timeline.event.suboff',
        [common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS.SUB_SIMPLE]:
            'label.timeline.event.sub',
        [common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS.SUB_ON]:
            'label.timeline.event.subon',
        [common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS.PENALTY]:
            'label.timeline.event.goal',
        [common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS
            .PENALTY_MISSED]: 'label.timeline.event.misspen',
        [common.constants.footballMatchTimeline.EVENT_DESCRIPTIONS
            .PENALTY_SAVED]: 'label.timeline.event.misspen'
    };

    common.constants.footballMatchTimeline.TIMELINE_PHASE = {
        FIRST_HALF: '1',
        HALF_TIME: 'H',
        SECOND_HALF: '2',
        FIRST_HALF_EXTRA_TIME: 'X1',
        SECOND_HALF_EXTRA_TIME: 'X2',
        FULL_TIME: 'F'
    };

    common.constants.footballMatchTimeline.TIMELINE_PHASES = [
        {
            phase: common.constants.footballMatchTimeline.TIMELINE_PHASE
                .FIRST_HALF,
            min: 0,
            max: 45,
            label: 'label.HT'
        },
        {
            phase: common.constants.footballMatchTimeline.TIMELINE_PHASE
                .SECOND_HALF,
            min: 45,
            max: 90,
            label: 'label.FT'
        },
        {
            phase: common.constants.footballMatchTimeline.TIMELINE_PHASE
                .FIRST_HALF_EXTRA_TIME,
            min: 90,
            max: 105,
            label: 'label.HTET'
        },
        {
            phase: common.constants.footballMatchTimeline.TIMELINE_PHASE
                .SECOND_HALF_EXTRA_TIME,
            min: 105,
            max: 120,
            label: 'label.AET'
        }
    ];

    common.constants.footballMatchTimeline.TIMELINE_PHASES_NORMAL = [
        common.constants.footballMatchTimeline.TIMELINE_PHASES[0],
        common.constants.footballMatchTimeline.TIMELINE_PHASES[1]
    ];

    common.constants.footballMatchTimeline.TIMELINE_PHASES_EXTRA = [
        /*eslint-disable no-magic-numbers*/
        common.constants.footballMatchTimeline.TIMELINE_PHASES[2],
        common.constants.footballMatchTimeline.TIMELINE_PHASES[3]
        /*eslint-enable no-magic-numbers*/
    ];

    common.constants.footballMatchTimeline.PHASE_ORDERING = [
        common.constants.footballMatchTimeline.TIMELINE_PHASE.FIRST_HALF,
        common.constants.footballMatchTimeline.TIMELINE_PHASE.HALF_TIME,
        common.constants.footballMatchTimeline.TIMELINE_PHASE.SECOND_HALF,
        common.constants.footballMatchTimeline.TIMELINE_PHASE
            .FIRST_HALF_EXTRA_TIME,
        common.constants.footballMatchTimeline.TIMELINE_PHASE
            .SECOND_HALF_EXTRA_TIME,
        common.constants.footballMatchTimeline.TIMELINE_PHASE.FULL_TIME
    ];

    /*
     * Brightcove Events
     */
    common.constants.BRIGHTCOVE_EVENTS = {
        /*
         * Triggered whenever an Tech#play event happens. Indicates that playback has started or resumed.
         * Docs: https://docs.brightcove.com/brightcove-player/current-release/Player.html#event:play
         */
        PLAY: 'play',

        /*
         * The media is no longer blocked from playback, and has started playing.
         * Docs: https://docs.brightcove.com/brightcove-player/current-release/Player.html#event:playing
         */
        PLAYING: 'playing',

        /*
         * Fired whenever the media has been paused
         * Docs: https://docs.brightcove.com/brightcove-player/current-release/Player.html#event:pause
         */
        PAUSE: 'pause',

        /*
         * Fired when the end of the media resource is reached (currentTime == duration)
         * Docs: https://docs.brightcove.com/brightcove-player/current-release/Player.html#event:ended
         */
        ENDED: 'ended',

        /*
         * Fired by brightcove player when the player changes in and out of fullscreen
         * Docs: https://docs.brightcove.com/brightcove-player/current-release/Player.html#event:fullscreenchange
         */
        FULLSCREEN_CHANGE: 'fullscreen',

        /*
         * On error
         * Docs: https://docs.brightcove.com/brightcove-player/current-release/Player.html#event:error
         */
        ERROR: 'error',

        /*
         * Video is restricted from playing in your current geographic region.
         * Docs: https://support.brightcove.com/brightcove-player-error-reference#Custom_Brightcove_errors
         */
        GEO_RESTRICTED: 'PLAYER_ERR_GEO_RESTRICTED'
    };

    common.constants.SSO_EVENTS = {
        OPEN_MODAL: 'sso/open-modal'
    };

    common.constants.EVENT_CATEGORIES = {
        GENERAL: 'GENERAL-SVNS',
        CPT: 'CPT-SVNS',
        DXB: 'DXB-SVNS',
        HKG: 'HKG-SVNS',
        LAX: 'LAX-SVNS',
        MAD: 'MAD-SVNS',
        SGP: 'SGP-SVNS',
        PER: 'PER-SVNS',
        VAN: 'VAN-SVNS'
    };

    common.constants.SERIES_IDS = {
        MRS: [
            'f9c35cdd-6618-4815-9113-c4e2309f28a5',
            '9789b531-35ba-4ee0-a790-782aad7578c3',
            '19b531d6-3e00-4574-8aaa-eb29c019dc29',
            '0bca81f1-3765-4419-bc65-9f934aa93b18',
            '27105044-a026-48fd-85b4-e3236f49d791',
            'd3161f09-ab74-4f8a-bfeb-758e20ae883e',
            'e35df4e0-8ac7-4979-a9d3-63ba38bd834e',
            '6d7ba344-d0d8-48d7-adb0-30743c831f32',
            'ae853b89-6161-4c43-a1b8-280d83b2b011',
            '24fe8481-b4f9-4ce8-af5d-391c60bd1536',
            '87cc3aa5-6739-4215-814b-e09886194c14',
            '29067bc2-9931-4deb-89ea-762d85c73803',
            '6da1deba-1361-4be9-9774-3723b45b8130',
            '629889a8-c188-4173-8f1a-0884063dfeab',
            '7ba72eff-953c-46bd-8e2c-d2789f41abb3',
            'f9aae467-1fa3-4600-a20e-f244e1c6aff3',
            '6517ddd7-a018-4f7e-bf50-4edfe046008b',
            '78c37124-884c-4ba8-8b5a-be3a86313fd9',
            'd3d83436-100b-40e8-8109-68417c80fa81',
            'be81c0c1-c508-4c8f-91fc-bd72a4902c2e',
            '0df540a9-2feb-441f-b826-f9381d3aea03',
            '98191bbf-73fb-478f-8611-50744766f462',
            'b88cbbb5-a24c-487c-a8c1-9c86464213ab',
            '7255ad4c-c74b-4b1c-b3aa-68b673eaad85',
            '1bdd551e-3f3d-4b58-938f-d8e337ea1d54'
        ],
        WRS: [
            'e2bed9e9-09d8-450f-be34-82ea1039eb70',
            '33cc06d8-23a0-495e-8140-9662f2265a52',
            '86e5ad07-9821-4d02-ab2b-6c39dcd8b23c',
            '3c63906a-dc83-4057-923a-44066c763f7d',
            '24743269-21f4-4d03-a4a4-3d2895c99e9c',
            '4c0fb247-1327-458c-bc41-0c58aec0a35d',
            '25a8f8cf-b42c-4bd5-8e59-17e928501114',
            'e06babd2-5b6e-4b81-8e1e-d653c151da15',
            'a8dc961e-827f-482b-8a73-412a6db2d74e',
            'ef0d76c6-c6a9-40e7-870a-9f5da55085a4',
            'ea60c122-69be-429b-9bd2-b36bdffbb330',
            '121a8a62-1131-4d61-bf33-fe3abac5ea69'
        ]
    }

})(PULSE.app.common);