(function (common) {
    common.renderCategoryLabel = function (tags) {
        let translationLabel = '';
        tags.forEach((tag) => {
            if (tag.label) {
                const cat = tag.label.split('-')[0];
                if (common.constants.EVENT_CATEGORIES[cat]) {
                    translationLabel =
                        'label.svns.category.' +
                        tag.label.replace('-', '').toLowerCase();
                }
            }
        });
        return translationLabel;
    };
})(PULSE.app.common);
