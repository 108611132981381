/*eslint no-param-reassign: "warn"*/
/*eslint new-cap: "warn"*/

(function (app) {
    'use strict';

    //Social constants
    const TWITTER = 'twitter';
    const FACEBOOK = 'facebook';
    const WHATSAPP = 'whatsapp';
    const GOOGLEPLUS = 'googleplus';
    const EMAIL = 'email';
    const FACEBOOK_MESSENGER = 'facebookMessenger';

    const TITLE_SELECTOR = '.js-page-title';

    const TWITTER_VIA = () => PULSE.I18N.lookup('label.twitter.via');
    const VIA = () => PULSE.I18N.lookup('label.social.via');

    /**
     * Create a set of basic functionality that social widgets will share
     * Individual social helpers can be extended with extra functions in ./social-service
     *
     * @param {string} serviceName name of the social service, should correlate to an entry
     * in socialLinks object
     *
     * @class
     */
    const SocialHelper = function SocialHelper(serviceName) {
        const _self = this;

        _self.name = serviceName;

        _self.socialLinks = {
            twitter: { url: 'http://www.twitter.com/intent/tweet?text=' },
            facebook: { url: 'http://www.facebook.com/sharer/sharer.php?u=' },
            facebookMessenger: { url: 'fb-messenger://share/?link=' },
            googleplus: { url: 'http://plus.google.com/share?url=' },
            whatsapp: { url: 'whatsapp://send?text=' },
            email: { url: 'mailto:?body=' }
        };

        _self.defaultWindowConfiguration = {
            width: '500',
            height: '500',
            menubar: 0,
            location: 1,
            resizable: 0,
            scrollbars: 0,
            status: 0,
            titlebar: 0,
            toolbar: 0
        };
    };

    /**
     * Share a page url or the current page url ( if no url passed ) to the social
     * media site with which the instance was created
     *
     * @param {string} url 		 the url to share on the social media site
     * @param {string} body 	 the share message
     * @returns {string} page url
     */
    SocialHelper.prototype.buildShareUrl = function buildShareUrl(url, body) {
        const _self = this;

        const shareURL = url || window.location.href;
        const pageTitle = getPageTitle();
        const network = _self.socialLinks[_self.name].url;

        switch (_self.name) {
            case FACEBOOK:
            case GOOGLEPLUS:
            case FACEBOOK_MESSENGER:
                return network + encodeURIComponent(shareURL);
            case TWITTER:
                if (body) {
                    return (
                        network +
                        encodeURIComponent(
                            `${body} ${TWITTER_VIA()} ${shareURL}`
                        )
                    );
                } else if (pageTitle) {
                    return (
                        network +
                        encodeURIComponent(
                            `${pageTitle} ${TWITTER_VIA()} ${shareURL}`
                        )
                    );
                }
                return (
                    network + encodeURIComponent(`${shareURL} ${TWITTER_VIA()}`)
                );

            default:
                if (pageTitle) {
                    return (
                        network +
                        encodeURIComponent(`${pageTitle} ${VIA()} ${shareURL}`)
                    );
                }
                return (
                    network + encodeURIComponent(`${body} ${VIA()} ${shareURL}`)
                );
        }
    };

    /**
     * creates a string representation of the configuration object provided so
     * it can be used in the call to window.open, for example;
     *
     * "menubar=no,location=yes,resizable=yes,scrollbars=yes,status=yes"
     *
     * @param {object} windowConfiguration the configuration object to stringify
     * @returns {string} comma separated list of configuration parameters
     */
    SocialHelper.prototype.makeWindowConfigString =
        function makeWindowConfigString(windowConfiguration) {
            const _self = this;

            const config =
                windowConfiguration || _self.defaultWindowConfiguration;
            const settings = Object.keys(config);

            return settings.reduce(
                (acc, cur) => (acc += `${cur}=${config[cur]},`),
                []
            );
        };

    /**
     * create a share url for the service with which the instance was created and open a
     * new window using the parameters provided, or the defaults.
     *
     * @param {string} url   optionally provide a specific url to link to, otherwise the current window.location
     * @param {object} windowConfiguration optionally provide a window configuration object
     * @param {string} body the share message to include if present
     */
    SocialHelper.prototype.sharePage = function sharePage(
        url,
        windowConfiguration,
        body
    ) {
        const _self = this;

        const shareURL = _self.buildShareUrl(url, body);
        const config = _self.makeWindowConfigString(windowConfiguration);

        window.open(shareURL, '_blank', config);
    };

    /**
     * Get page title
     *
     * @returns { HTMLElement } return selected element or null
     */
    const getPageTitle = () => {
        return document.querySelector(TITLE_SELECTOR);
    };

    /**
     * keep the social helpers under the app object
     *
     * @type {{twitter: socialHelper, facebook: socialHelper, google: socialHelper}}
     */
    app.socialHelpers = {
        twitter: new SocialHelper(TWITTER),
        facebook: new SocialHelper(FACEBOOK),
        google: new SocialHelper(GOOGLEPLUS),
        email: new SocialHelper(EMAIL),
        whatsapp: new SocialHelper(WHATSAPP),
        facebookMessenger: new SocialHelper(FACEBOOK_MESSENGER)
    };
})(PULSE.app);
