(function (common, core, constants, i18n) {
    const TWO_DIGITS = 2;

    const UNIT = {
        months: 'months',
        days: 'days',
        hours: 'hours',
        minutes: 'minutes',
        seconds: 'seconds'
    };

    const getLabel = function (label) {
        return `label.countdown.svns.${label}`;
    };

    const UNIT_TRANSLATION = {
        months: getLabel('month'),
        days: getLabel('day'),
        hours: getLabel('hour'),
        hrs: getLabel('hr'),
        minutes: getLabel('minute'),
        mins: getLabel('min'),
        seconds: getLabel('second'),
        secs: getLabel('sec')
    };

    /**
     * Counts down to a given time
     *
     * @param {DOMElement} element - widget wrapper element
     * @class
     */
    common.Countdown = function (element) {
        const _self = this;

        _self.element = element;
        _self.unitSections = _self.element.querySelectorAll('[data-unit]');
        _self.dataMatchStart = _self.element.getAttribute('data-match-start');
        let now = new Date().getTime();
        _self.matchStart = Math.floor(new Date(_self.dataMatchStart).getTime());
        _self.showSeconds =
            _self.element.getAttribute('data-show-seconds') === 'true';
        _self.abbreviatedLabels =
            _self.element.getAttribute('data-abbreviated-labels') === 'true';

        if (!Number.isNaN(_self.matchStart) && _self.matchStart > now) {
            _self.initCountdown();
        }
    };

    /**
     * Initialises countdown
     */
    common.Countdown.prototype.initCountdown = function () {
        const _self = this;

        // clock is hidden by default, reveal element when initialised
        core.style.removeClass(_self.element, 'u-hide');

        let startTime = _self.matchStart;
        let format = _self.showSeconds
            ? [UNIT.days, UNIT.hours, UNIT.minutes, UNIT.seconds]
            : [UNIT.days, UNIT.hours, UNIT.minutes];

        _self.setFormat(format);

        _self.refreshTime(startTime);

        _self.liveRefresh = setInterval(function () {
            _self.refreshTime(startTime);
        }, constants.time.oneSecond);
    };

    /**
     * Stops countdown
     */
    common.Countdown.prototype.stopCountdown = function () {
        const _self = this;
        clearInterval(_self.liveRefresh);
    };

    /**
     * When months value reaches zero, switch from Months/Days/Hours, to Days/Hours/Minutes
     *
     * @param {Array} format - contains a series of initials to indicate the new format (e.g. ['D', 'H', 'M'])
     */
    common.Countdown.prototype.setFormat = function (format) {
        let _self = this;

        Array.prototype.forEach.call(
            _self.unitSections,
            function (unitSection) {
                let attribute = unitSection.getAttribute('data-unit');
                if (format.indexOf(attribute) > -1) {
                    core.style.removeClass(unitSection, 'u-hide');
                } else {
                    core.style.addClass(unitSection, 'u-hide');
                }
            }
        );
    };

    /**
     * Make new calculation on time until start date
     *
     * @param {Integer} startTime - millis value of event time to count down to
     */
    common.Countdown.prototype.refreshTime = function (startTime) {
        const _self = this;

        let now = new Date().getTime();
        let timeGap = startTime - now;

        let eventMoment = moment(startTime);
        let nowMoment = moment(now);

        let daysFromNow = eventMoment.diff(nowMoment, UNIT.days);
        let daysOfEventMoment = nowMoment.add(daysFromNow, UNIT.days);
        let dayOfEventMoment = moment(daysOfEventMoment);
        let hoursLeftOver = eventMoment.diff(
            moment(dayOfEventMoment),
            UNIT.hours
        );
        let hourOfEventMoment = moment(dayOfEventMoment).add(
            hoursLeftOver,
            UNIT.hours
        );
        let minsLeftOver = eventMoment.diff(
            moment(hourOfEventMoment),
            UNIT.minutes
        );
        let minOfEventMoment = moment(hourOfEventMoment).add(
            minsLeftOver,
            UNIT.minutes
        );
        let secondsLeftOver = eventMoment.diff(
            moment(minOfEventMoment),
            UNIT.seconds
        );

        let remaining = {
            days: daysFromNow,
            hours: hoursLeftOver,
            minutes: minsLeftOver,
            seconds: secondsLeftOver
        };

        if (timeGap <= 0) {
            // once clock reaches zero, stop countdown refresh
            _self.stopCountdown();
            core.style.addClass(_self.element, 'countdown-finished');
            return;
        }

        _self.renderTime(remaining);
    };

    /**
     * Re-render clock values
     *
     * @param {object} remaining - remaining of each unit until given date
     */
    common.Countdown.prototype.renderTime = function (remaining) {
        const _self = this;

        _self.renderUnit(UNIT.days, remaining);
        _self.renderUnit(UNIT.hours, remaining);
        _self.renderUnit(UNIT.minutes, remaining);
        _self.renderUnit(UNIT.seconds, remaining);
    };

    /**
     * Render time
     *
     * @param {string} unit - unit of time ('months, days, hours, minutes')
     * @param {object} remaining - remaining of each unit until given date
     */
    common.Countdown.prototype.renderUnit = function (unit, remaining) {
        let _self = this;

        let element = _self.element.querySelector('[data-unit=' + unit + ']');
        let digits = element.querySelector('.js-digits');
        let label = element.querySelector('.js-label');

        let value = remaining[unit];
        let numDigits = value.toString().length;
        let printValue = numDigits < TWO_DIGITS ? `0${value}` : value;
        let printLabel = i18n.lookup(UNIT_TRANSLATION[unit]);

        // If abbreviated labels are required, update the labels
        if (unit === 'hours' && _self.abbreviatedLabels) {
            printLabel = i18n.lookup(UNIT_TRANSLATION.hrs);
        }

        if (unit === 'minutes' && _self.abbreviatedLabels) {
            printLabel = i18n.lookup(UNIT_TRANSLATION.mins);
        }

        if (unit === 'seconds' && _self.abbreviatedLabels) {
            printLabel = i18n.lookup(UNIT_TRANSLATION.secs);
        }

        digits.innerHTML = printValue;
        label.innerHTML = printLabel;
    };
})(PULSE.app.common, PULSE.core, PULSE.app.common.constants, PULSE.I18N);
