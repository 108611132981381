(function (app) {
    /**
     * app.StreamAMGController
     *
     * Global object used to manage instances of media players in a page. Will
     * request and load the KDP api on creation based on the;
     *
     * app.environment.streamAmg.uiConfig
     * app.environment.streamAmg.partner
     *
     * application parameters.
     *
     * @param uiConfigId
     * @params {number} uiConfigId - you can specify a uiConfigId. By default
     * the controller will use the one defined in
     * `app.environment.streamAmg.uiConfig`.
     */
    app.StreamAMGController = function (uiConfigId = null) {
        const _self = this;
        const stream = app.environment.streamAmg;

        _self.endpoint = app.environment.streamAmg.script(
            stream.partner,
            uiConfigId || stream.uiConfig
        );
        _self.ready = false;
        _self.players = [];
        _self.readyTargets = [];
        _self.createAPIScript();
    };

    /**
     * define a callback to be invoked when the KDP has loaded. Will be called
     * synchronously if the KDP is already loaded.
     *
     * @param {Function} callback the callback function ton invoke
     */
    app.StreamAMGController.prototype.onKDPReady = function (callback) {
        const _self = this;

        if (_self.ready) {
            callback();
            return;
        }

        _self.readyTargets.push(callback);
    };

    app.StreamAMGController.prototype.addPlayer = function (videoPlayer) {
        const _self = this;
        _self.players.push(videoPlayer);
    };

    app.StreamAMGController.prototype.apiLoaded = function () {
        const _self = this;

        _self.ready = true;
        _self.readyTargets.forEach(function (callback) {
            callback();
        });
    };

    app.StreamAMGController.prototype.createAPIScript = function () {
        const _self = this;
        const scriptElement = document.createElement('script');

        scriptElement.src = _self.endpoint;
        scriptElement.type = 'text/javascript';
        scriptElement.onload = _self.apiLoaded.bind(_self);
        document.body.appendChild(scriptElement);
    };
})(PULSE.app);
