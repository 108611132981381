(function (common) {
    'use strict';

    if (typeof common.security === 'undefined') {
        common.security = {};
    }

    /**
     * Will sanitize string for safe use. Removes dangerous characters from
     * string.
     *
     * @param {string} string - string to santize
     * @returns {string} - sanitized string
     *
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Using_parentheses
     */
    common.security.sanitize = (string) => {
        return string.replace(/(&|<|"|\/|>|\(|\)|;)/g, '');
    };
})(PULSE.app.common);
