/* eslint-disable no-undef */
(function (app, core, common) {
    const PLAYERS = {};
    const PLAYER_NAMES = [];
    const CREATED_SCRIPTS = [];

    // Number of seconds to increment/decrement time when clicking the forwards/backward buttons
    const JUMP_AMOUNT_SECS = 15; // eslint-disable-line no-magic-numbers

    app.PlayerManager = class PlayerManager {
        /**
         * the player manager will maintain a list of the active players on the page.
         * each time a video player controller instantiates in the page it will pass a
         * reference to itself to the player manager.
         *
         * The manager will also handle creating the single brightcove script in the page.
         * when brightcove creates the players the manager will handle the onload callback and
         * add the api functionality to the controller
         *
         * @class
         */
        constructor() {
            this.scriptSource =
                '//players.brightcove.net/{{account}}/{{playerId}}_default/index.min.js';
            this.createScripts();
        }

        /**
         * remove a player
         *
         * @param {string} playerName name of the player to remove
         */
        removePlayer(playerName) {
            const index = PLAYER_NAMES.indexOf(playerName);

            if (index > -1) {
                PLAYER_NAMES.splice(index, 1);

                videojs(playerName).dispose();
                clearTimeout(this.timeout);
            }
        }

        /**
         * get a player with a specific name ( BrightcoveID )
         *
         * @param {string} name the name of the player
         * @param {boolean} ensureReturn weather to return a player if the specified player cant be found
         * @returns {object} video player controller implementing the video player superclass ( app.BrightCoveVideo )
         */
        getPlayerWithName(name, ensureReturn) {
            let lookup = PLAYER_NAMES.filter((player) => player === name);

            if (lookup.length > 0) {
                return PLAYERS[lookup[0]];
            }

            if (ensureReturn && PLAYERS[0]) {
                return PLAYERS[0];
            }

            return false;
        }

        /**
         * create the brightcove player and add a representation of it to the global brightcove object
         *
         * @param  {object} videoDetails Player video details
         * @param  {Function} callback Function to call once the player has been found
         */
        createPlayer(videoDetails, callback) {
            const _self = this;
            const playerName = videoDetails.elementId;
            const bcPlayerId = videoDetails.playerId;
            const tags = videoDetails.tags;
            const controls = videoDetails.controls;
            const loop = videoDetails.loop;

            videojs(playerName).ready(function () {
                //wrap the video object in brightcove video to add convinience methods
                const playerSettings = {
                    video: { id: this.el_.dataset.videoId },
                    target: this.el_.parentElement,
                    controls: controls,
                    autoplay: true,
                    setEndScreen:
                        this.getAttribute('data-set-end-screen') === 'true'
                            ? true
                            : false,
                    player: bcPlayerId,
                    tags
                };

                if (playerSettings.controls) {
                    _self.registerForwardBackButtons.call(
                        this,
                        playerSettings.target
                    );
                    _self.registerBigPauseButton.call(
                        this,
                        playerSettings.target
                    );
                }

                PLAYERS[this.id_] = new common.VideoPlayer(playerSettings);

                if (typeof callback === 'function') {
                    callback(this.id_);
                }

                if (loop) {
                    videojs(this.id_).on('ended', function () {
                        this.play();
                    });
                }

                videojs(this.id_).on('play', function () {
                    common.fireEvent(
                        common.constants.BRIGHTCOVE_EVENTS.PLAY,
                        {
                            video: this,
                            videoId: this.el_.dataset.videoId
                        },
                        document.body
                    );
                    clearTimeout(_self.timeout);
                });

                videojs(this.id_).on('pause', function () {
                    common.fireEvent(
                        common.constants.BRIGHTCOVE_EVENTS.PAUSE,
                        {
                            video: this,
                            videoId: this.el_.dataset.videoId
                        },
                        document.body
                    );
                });

                videojs(this.id_).on('ended', function () {
                    common.fireEvent(
                        common.constants.BRIGHTCOVE_EVENTS.END,
                        {
                            video: this,
                            videoId: this.el_.dataset.videoId
                        },
                        document.body
                    );
                });

                videojs(this.id_).on('fullscreenchange', function () {
                    common.fireEvent(
                        common.constants.BRIGHTCOVE_EVENTS.FULLSCREEN_CHANGE,
                        {
                            video: this
                        },
                        document.body
                    );
                });
            });
        }

        /**
         * Add forward/back buttons and set listeners to jump video time
         * forwards/backwards by the amount set in the constant JUMP_AMOUNT_SECS
         *
         * @param {HTMLElement} videoEl - Video element for this player
         */
        registerForwardBackButtons(videoEl) {
            const myPlayer = this; // eslint-disable-line consistent-this
            const jumpAmount = JUMP_AMOUNT_SECS;
            const newElementBB = document.createElement('div');
            const newElementFB = document.createElement('div');

            // Assign IDs for later element manipulation
            newElementBB.id = 'vjs-back-button';
            newElementFB.id = 'vjs-forward-button';

            // Get video player element and append buttons
            const videoPlayerEl = videoEl.querySelector('.video-js');
            videoPlayerEl.appendChild(newElementBB);
            videoPlayerEl.appendChild(newElementFB);

            // Back button logic, don't jump to negative times
            newElementBB.addEventListener('click', function () {
                let newTime,
                    rewindAmt = jumpAmount,
                    videoTime = myPlayer.currentTime();
                if (videoTime >= rewindAmt) {
                    newTime = videoTime - rewindAmt;
                } else {
                    newTime = 0;
                }

                myPlayer.currentTime(newTime);
            });

            // Forward button logic, don't jump past the duration
            newElementFB.addEventListener('click', function () {
                let newTime,
                    forwardAmt = jumpAmount,
                    videoTime = myPlayer.currentTime(),
                    videoDuration = myPlayer.duration();
                if (videoTime + forwardAmt <= videoDuration) {
                    newTime = videoTime + forwardAmt;
                } else {
                    newTime = videoDuration;
                }

                myPlayer.currentTime(newTime);
            });
        }

        /**
         * Add pause button and set listener
         * Pause button for centre of video player
         *
         * @param {HTMLElement} videoEl - Video element for this player
         */
        registerBigPauseButton(videoEl) {
            const myPlayer = this; // eslint-disable-line consistent-this
            const newElementBP = document.createElement('div');

            // Assign IDs for later element manipulation
            newElementBP.id = 'vjs-big-pause-button';

            // Get video player element and append button
            const videoPlayerEl = videoEl.querySelector('.video-js');
            videoPlayerEl.appendChild(newElementBP);

            newElementBP.addEventListener('click', function () {
                myPlayer.pause();
            });
        }

        /**
         * allow us to register our internal callback functions before loading the brightcove script
         * otherwise load events are called before functions actually exist
         *
         * @param {Function} callback Function to call once the script has loaded
         */
        createScripts(callback) {
            const _self = this;

            //loop over the video elements in the page
            const videos = document.querySelectorAll('video');

            Array.prototype.map.call(videos, (videoEl) => {
                const account = videoEl.getAttribute('data-account');
                const playerId = videoEl.getAttribute('data-player');
                const controls = videoEl.getAttribute('data-controls');
                const loop = videoEl.getAttribute('data-loop');
                const elementId = videoEl.getAttribute('id');
                const tags = videoEl.dataset.videoTags
                    ? JSON.parse(videoEl.dataset.videoTags)
                    : null;

                const videoDetails = {
                    account,
                    playerId,
                    controls: controls === 'true',
                    loop: loop === 'true',
                    elementId: elementId ? trimHtml5Suffix(elementId) : null,
                    tags: tags ? tags : null
                };

                if (!videoDetails.playerId || !videoDetails.account) {
                    return;
                }

                if (PLAYER_NAMES.indexOf(videoDetails.elementId) === -1) {
                    PLAYER_NAMES.push(videoDetails.elementId);
                }

                const src = core.common.formatString(
                    this.scriptSource,
                    videoDetails
                );
                const dataScriptPlayer =
                    document.querySelector(
                        `script[data-player=${videoDetails.elementId}]`
                    ) || false;
                const index = CREATED_SCRIPTS.indexOf(src);

                if (CREATED_SCRIPTS.indexOf(src) > -1 && dataScriptPlayer) {
                    /*
                        this is necessary for the Video Modal, if the user re-opens the same video in a modal.
                    */
                    CREATED_SCRIPTS.splice(index, 1);
                    dataScriptPlayer.parentElement.removeChild(
                        dataScriptPlayer
                    );
                }

                let scrpt = document.createElement('script');
                scrpt.setAttribute('data-player', videoDetails.elementId);
                document.getElementsByTagName('body')[0].appendChild(scrpt);

                scrpt.onload = function () {
                    _self.createPlayer(videoDetails, callback);
                };

                scrpt.onerror = function () {
                    // remove the player id from list of PLAYER_NAMES because the player does not exist
                    const indexOfPlayerId = PLAYER_NAMES.indexOf(
                        videoDetails.elementId
                    );
                    if (indexOfPlayerId > -1) {
                        PLAYER_NAMES.splice(indexOfPlayerId, 1);
                    }
                };

                scrpt.src = src;
                CREATED_SCRIPTS.push(src);
            });
        }
    };

    const trimHtml5Suffix = (playerName) => playerName.split('_html5_api')[0];

    app.videoPlayerManager = new app.PlayerManager();
})(PULSE.app, PULSE.core, PULSE.app.common);
