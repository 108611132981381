(function (common) {
    common.getCategoryFromTag = function (tags) {
        let category = '';
        tags.forEach((tag) => {
            if (tag.label) {
                const cat = tag.label.split('-')[0];
                if (common.constants.EVENT_CATEGORIES[cat]) {
                    category = tag.label.toLowerCase();
                }
            }
        });
        return category;
    };
})(PULSE.app.common);
