(function (app, common, core) {
    'use strict';

    const THREE = 3;

    const IMG_CLS = [
        'content-listing__picture-wrapper--56',
        'content-listing__picture-wrapper--75',
        'content-listing__picture-wrapper--100'
    ];

    common.ContentListing = function (widget) {
        const _self = this;

        _self.container = widget;
        const contentList = _self.container.querySelector('.js-content-list');
        _self.wrappers = contentList.querySelectorAll('.js-picture-wrapper');

        _self.randomiseLayout();
    };

    common.ContentListing.prototype.randomiseLayout = function () {
        const _self = this;

        _self.wrappers.forEach(function (wrapper, idx) {
            let randomNumber = returnRandomNumber();

            const isEven = idx % 2 !== 0;

            /* Generate different size to previous image */
            while (
                isEven &&
                core.style.hasClass(
                    _self.wrappers[idx - 1],
                    IMG_CLS[randomNumber]
                )
            ) {
                randomNumber = returnRandomNumber();
            }

            /* Generate size that differs from next image (set in ftl) */
            if (!isEven && _self.wrappers[idx + 1]) {
                while (
                    core.style.hasClass(
                        _self.wrappers[idx + 1],
                        IMG_CLS[randomNumber]
                    )
                ) {
                    randomNumber = returnRandomNumber();
                }
            }

            /* Check if image already has size class */
            let hasImgCls = false;
            IMG_CLS.forEach((imgCls) => {
                if (core.style.hasClass(wrapper, imgCls)) {
                    hasImgCls = true;
                }
            });

            if (!hasImgCls) {
                core.style.addClass(wrapper, IMG_CLS[randomNumber]);
            }

            _self.lastNumber = randomNumber;
        });
    };

    const returnRandomNumber = function () {
        return Math.floor(Math.random() * THREE);
    };

    const widgets = document.querySelectorAll(
        '[data-widget="content-listing"]'
    );
    [].forEach.call(widgets, (widget) => new common.ContentListing(widget));
})(PULSE.app, PULSE.app.common, PULSE.core);
