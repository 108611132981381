(function (common) {
    /**
     * generate a new custom event and fire it on the given element
     *
     * @param {string} name the name of the event
     * @param {object} [data={}] the data to pass to the event
     * @param {HTMLElement} [element=document.body] the element where we should fire the event
     */
    common.fireEvent = function (name, data = {}, element = document.body) {
        element.dispatchEvent(
            new CustomEvent(name, {
                detail: data
            })
        );
    };
})(PULSE.app.common);
