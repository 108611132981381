(function (app, common) {
    'use strict';

    const PLACEHOLDER_SELECTOR = '.js-lazy-load';
    const HIDE_CLASS = 'u-hide';

    app.PlayerCardImage = function PlayerCardImage(container) {
        const _self = this;
        _self.container = container;
        _self.playerId = container.dataset.player;

        if (_self.playerId === null) {
            return;
        }

        _self.placeholder = container.querySelector(PLACEHOLDER_SELECTOR);
        _self.placeholder.classList.add(HIDE_CLASS);

        const url = common.image.getPlayerImage(_self.playerId);
        const element = scaffoldImage.call(_self, url);
        container.appendChild(element);
    };

    const scaffoldImage = function scaffoldImage(url) {
        const _self = this;
        const img = document.createElement('img');

        img.onerror = function imageError() {
            this.classList.add('player-card__player-image--error');
            _self.placeholder.classList.remove(HIDE_CLASS);
        };

        img.setAttribute('src', url);

        return img;
    };

    app.widgetInitialiser.addMultipleWidgetsByName(
        'player-card-image',
        app.PlayerCardImage
    );
})(PULSE.app, PULSE.app.common);
