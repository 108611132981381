(function (app, common, core) {
    'use strict';

    const configTests = [
        {
            namespace: 'container',
            elementType: HTMLElement
        },
        {
            namespace: 'scrollContainer',
            elementType: HTMLElement
        },
        {
            namespace: 'sliderElements',
            elementType: NodeList
        },
        {
            namespace: 'nextButton',
            elementType: HTMLElement
        },
        {
            namespace: 'prevButton',
            elementType: HTMLElement
        },
        {
            namespace: 'scrollList',
            elementType: HTMLElement
        },
        {
            namespace: 'paginatorContainer',
            elementType: HTMLElement
        }
    ];

    const HIDE_CLASS = 'u-hide';

    /**
     * @typedef {object} SliderConfig configuration for the LFC slider element
     * @property {HTMLElement} container the container of the slider element
     * @property {Integer} itemsPerPage how many li elements should be incremented per slide
     * @property {HTMLElement} scrollContainer the element that will be scrolled in the slider
     * @property {NodeList} sliderElements list of slide elements (li's)
     * @property {HTMLElement} nextButton the next button
     * @property {HTMLElement} prevButton the previous button
     * @property {NodeList} pagerElements the page indication elements
     * @property {string} nudgeLeftClass the class to apply to the container to nudge it left
     * @property {string} nudgeRightClass the class to apply to the container to nudge it right
     * @property {string} pagerActiveClass the class to apply to an active pager element
     */

    /**
     * Content Slider, creates slider interaction from a list, displays next and
     * previous buttons and a bottom pagination indicator
     *
     * @param {SliderConfig} config the slider configuration
     * @class
     */
    common.ContentCarousel = function ContentCarousel(config) {
        const validConfig = common.validateConfig(
            'ContentCarousel',
            config,
            configTests
        );
        if (!validConfig) {
            return;
        }

        const _self = this;
        _self.config = config;
        _self.container = _self.config.container;
        _self.initSlider();
    };

    common.ContentCarousel.prototype.initSlider = function initSlider() {
        const _self = this;

        _self.element = _self.config.container;
        _self.slideContainer = _self.config.scrollContainer;
        _self.slideWidth = _self.slideContainer.clientWidth;
        _self.slideItems = _self.config.sliderElements;
        _self.itemsPerPage = parseInt(_self.config.itemsPerPage);
        _self.slideNum = Math.ceil(
            _self.slideItems.length / _self.itemsPerPage
        );
        _self.slidePos = 0;
        _self.activeSlide = 0;
        _self.slideElementWidth =
            _self.slideItems[0].clientWidth * _self.itemsPerPage;
        _self.nextBtn = _self.config.nextButton;
        _self.prevBtn = _self.config.prevButton;
        _self.paginatorContainer = _self.config.paginatorContainer;
        _self.fullScroll = true;
        _self.itemsLeft = _self.slideItems.length;

        _self.setListeners();
        _self.updateControls();
        _self.resetSlider();
    };

    common.ContentCarousel.prototype.setListeners = function setListeners() {
        const _self = this;

        if (_self.nextBtn) {
            common.addAriaClickListener(_self.nextBtn, function () {
                _self.scrollNext();
            });

            //set nudge listeners so that on button hovers the slider moves left and right slightly
            _self.nextBtn.addEventListener('mouseover', function () {
                _self.nudgeLeft();
            });

            _self.nextBtn.addEventListener('mouseout', function () {
                _self.nudgeLeft();
            });
        }

        if (_self.prevBtn) {
            _self.prevBtn.addEventListener('mouseover', function () {
                _self.nudgeRight();
            });

            _self.prevBtn.addEventListener('mouseout', function () {
                _self.nudgeRight();
            });

            common.addAriaClickListener(_self.prevBtn, function () {
                _self.scrollPrev();
            });
        }

        core.event.windowResize.add({
            method: _self.resetSlider.bind(_self)
        });

        if (_self.paginatorContainer) {
            _self.renderPaginator();
        }
    };

    /**
     * Render paginator
     */
    common.ContentCarousel.prototype.renderPaginator =
        function renderPaginator() {
            const _self = this;

            if (_self.slideNum > 1) {
                // render paginator
                _self.paginatorContainer.innerHTML = app.templating.render(
                    { slideNum: _self.slideNum },
                    'common.paginator'
                );

                _self.pagerContainers =
                    _self.element.getElementsByClassName('js-pager-container');
                _self.pagers = _self.element.getElementsByClassName('js-pager');

                // make pager 1 active on intialisation
                core.style.addClass(_self.pagers[0], 'is-active');

                [].forEach.call(_self.pagerContainers, (pagerBtn, index) => {
                    common.addAriaClickListener(pagerBtn, (evt) => {
                        _self.activeSlide = index;
                        _self.slideTo(_self.activeSlide);
                        evt.stopPropagation();
                    });
                });

                core.style.removeClass(_self.prevBtn, HIDE_CLASS);
                core.style.removeClass(_self.nextBtn, HIDE_CLASS);
            } else {
                if (_self.prevBtn) {
                    core.style.addClass(_self.prevBtn, HIDE_CLASS);
                }
                if (_self.nextBtn) {
                    core.style.addClass(_self.nextBtn, HIDE_CLASS);
                }
            }
        };

    //one slide right
    common.ContentCarousel.prototype.scrollNext = function scrollNext() {
        const _self = this;

        _self.activeSlide++;
        _self.slideTo(_self.activeSlide);
        _self.updateControls();
        _self.setActiveItems();
    };

    common.ContentCarousel.prototype.nudgeLeft = function nudgeLeft() {
        const _self = this;
        core.style.toggleClass(
            _self.slideContainer,
            _self.config.nudgeLeftClass
        );
    };

    common.ContentCarousel.prototype.nudgeRight = function nudgeRight() {
        const _self = this;
        core.style.toggleClass(
            _self.slideContainer,
            _self.config.nudgeRightClass
        );
    };

    common.ContentCarousel.prototype.isPaginating = function isPaginating() {
        const _self = this;
        return _self.pagerItemsContainer
            ? _self.pagerItemsContainer.offsetParent
            : false;
    };

    //one slide left
    common.ContentCarousel.prototype.scrollPrev = function scrollPrev() {
        const _self = this;

        _self.activeSlide--;
        _self.slideTo(_self.activeSlide);
        _self.updateControls();
        _self.setActiveItems();
    };

    //when pager is clicked jump the the slide that matches that buttons index
    common.ContentCarousel.prototype.slideTo = function slideTo(slideIndex) {
        const _self = this;

        _self.getItemsLeft();
        _self.scrollSlider();
        _self.activeSlide = slideIndex;
        _self.updateControls();
        _self.setActiveItems();
    };

    /**
     * Getting the amount of items left
     * so we can set whether a full scroll is needed
     * or if a scroll of the remainder is required
     */
    common.ContentCarousel.prototype.getItemsLeft = function getItemsLeft() {
        const _self = this;

        _self.itemsLeft =
            _self.slideItems.length - _self.activeSlide * _self.itemsPerPage;
        if (_self.itemsLeft < _self.itemsPerPage) {
            _self.fullScroll = false;
        } else {
            _self.fullScroll = true;
        }
    };

    /**
     * Checks if a full scroll is needed and then scrolls the slider
     */
    common.ContentCarousel.prototype.scrollSlider = function scrollSlider() {
        const _self = this;

        if (_self.fullScroll) {
            _self.slidePos = _self.slideElementWidth * _self.activeSlide;
            _self.slideContainer.style.left = '-' + _self.slidePos + 'px';
        } else {
            _self.slidePos =
                _self.slideElementWidth * _self.activeSlide -
                _self.slideItems[0].clientWidth *
                    (_self.itemsPerPage - _self.itemsLeft);
            _self.slideContainer.style.left = '-' + _self.slidePos + 'px';
        }
    };

    common.ContentCarousel.prototype.updateControls =
        function updateControls() {
            const _self = this;

            //if there are no more slides after this hide next button
            if (_self.activeSlide >= _self.slideNum - 1 && _self.nextBtn) {
                core.style.addClass(_self.nextBtn, 'faded');
                //otherwise show this button
            } else if (
                _self.nextBtn &&
                core.style.hasClass(_self.nextBtn, 'faded')
            ) {
                core.style.removeClass(_self.nextBtn, 'faded');
            }

            if (_self.activeSlide === 0 && _self.prevBtn) {
                core.style.addClass(_self.prevBtn, 'faded');
            } else if (
                _self.prevBtn &&
                core.style.hasClass(_self.prevBtn, 'faded')
            ) {
                core.style.removeClass(_self.prevBtn, 'faded');
            }

            if (_self.paginatorContainer && _self.pagers) {
                //update the active pager button to match the activeSlide number
                for (let i = 0; i < _self.pagers.length; i++) {
                    if (i === _self.activeSlide) {
                        core.style.addClass(_self.pagers[i], 'is-active');
                    } else {
                        core.style.removeClass(_self.pagers[i], 'is-active');
                    }
                }
            }
        };

    common.ContentCarousel.prototype.getActiveSlide =
        function getActiveSlide() {
            const _self = this;
            return _self.activeSlide;
        };

    //function to set the four items currently active in slider so they're shown with full opacity
    common.ContentCarousel.prototype.setActiveItems =
        function setActiveItems() {
            const _self = this;
            let firstItemIndex;

            for (var i = 0; i < _self.slideItems.length; i++) {
                core.style.removeClass(_self.slideItems[i], 'is-active');
            }

            if (_self.fullScroll) {
                firstItemIndex = _self.activeSlide * _self.itemsPerPage;
            } else {
                firstItemIndex =
                    _self.activeSlide * _self.itemsPerPage -
                    (_self.itemsPerPage - _self.itemsLeft);
            }

            for (i = 0; i < _self.itemsPerPage; i++) {
                core.style.addClass(
                    _self.slideItems[firstItemIndex],
                    'is-active'
                );
                firstItemIndex++;
            }
        };

    /**
     * Check if the given element is visible within the scroll view of the carousel slider
     *
     * @param  {HTMLElement} element The element to test if it is visible within the scroll container
     * @param  {HTMLElement} wrapper The scroll container to set the bounding restrictions for which elements are tested against
     * @returns {boolean}         Returns true if the passed in element is visible inside the wrapper
     */
    common.ContentCarousel.prototype.isVisibleInSlideView = (
        element,
        wrapper
    ) => {
        const rect = element.getBoundingClientRect();
        const containerRect = wrapper.getBoundingClientRect();

        const topInView = rect.top >= containerRect.top;
        const bottomInView = rect.bottom <= containerRect.bottom;
        const leftInView = rect.left >= containerRect.left;
        const rightInView = rect.right <= containerRect.right;

        return topInView && bottomInView && leftInView && rightInView;
    };

    common.ContentCarousel.prototype.resetSlider = function resetSlider() {
        const _self = this;

        if (window.innerWidth > app.measurements.tablet) {
            /**
             * Iterates through slide container elements
             * and counts how many are visible
             * then sets the page count based on this
             */
            let sliderCount = 0;
            [].forEach.call(_self.slideContainer.children, (item) => {
                if (_self.isVisibleInSlideView(item, _self.slideContainer)) {
                    sliderCount++;
                }
            });
            _self.itemsPerPage = sliderCount;
        }

        _self.slidePos = 0;
        _self.slideElementWidth =
            _self.slideItems[0].clientWidth * _self.itemsPerPage;
        _self.slideNum = Math.ceil(
            _self.slideItems.length / _self.itemsPerPage
        );
        _self.activeSlide = 0;
        _self.renderPaginator();
        _self.slideTo(_self.activeSlide);
    };
})(PULSE.app, PULSE.app.common, PULSE.core);
