(function (app, core, common) {
    'use strict';

    const YEAR = 365;
    const COOKIE_EXPIRY = YEAR * 2; // 2 years as days

    /**
     * Shows the cookie notice banner if the user has not made a decision on cookies
     */
    const checkCookieOnLoad = () => {
        //Check in set cookies to see if cookie policy has been chosen
        const cookiePolicyChosen = core.localStorage.getStorage(
            'cookie-policy-chosen',
            true
        );

        if (!cookiePolicyChosen || cookiePolicyChosen !== 'true') {
            displayCookieNotice();
        }
    };

    /**
     * Query DOM for the cookie notice banner then display it
     * Also adds event listeners to the user input buttons
     */
    const displayCookieNotice = () => {
        const privacyMessage = document.getElementById('js-cookie-notice');

        //Return silently if there is no privacy message
        if (!privacyMessage) {
            return;
        }

        //Set a temporary value of agreeing to essential cookies to be overwritten by the user
        core.localStorage.setStorage('only-essential-cookies', true);

        //Add event listener to all buttons
        const cookieButtons = document.querySelectorAll(
            '.js-cookie-notice-btn'
        );
        cookieButtons.forEach(function (button) {
            button.addEventListener('click', common.setCookies);
        });

        core.style.removeClass(privacyMessage, 'u-hide');
    };

    /**
     * Updating local storage value with user's decision.
     *
     * @param {object} [event] the click event from the button which determines the user's decision
     */
    common.setCookies = (event) => {
        const hostname = document.location.hostname;
        const privacyMessage = document.getElementById('js-cookie-notice');

        // Updates local storage based on user's decision
        const allCookies = event.currentTarget.dataset.acceptAllCookies;
        const updateStorage = (decision) => {
            core.localStorage.setStorage('only-essential-cookies', decision);
            core.localStorage.setStorage(
                'cookie-policy-chosen',
                true,
                COOKIE_EXPIRY,
                true,
                hostname
            );
        };

        //Logic to determine user's decision and calls update storage with their decision
        if (allCookies === 'true') {
            updateStorage(false);
            const cookiesAccepted = new CustomEvent('cookiesAccepted', {
                detail: 'all'
            });
            window.dispatchEvent(cookiesAccepted);
        } else if (allCookies === 'false') {
            updateStorage(true);
            const cookiesAccepted = new CustomEvent('cookiesAccepted', {
                detail: 'essential'
            });
            window.dispatchEvent(cookiesAccepted);
        }

        core.style.addClass(privacyMessage, 'u-hide');
    };

    checkCookieOnLoad();
})(PULSE.app, PULSE.core, PULSE.app.common);
