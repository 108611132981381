(function (app, common) {
    const VIDEO_PLAYER_TEMPLATE = 'common.video-player';

    const AUTH_TAG = 'wr-authenticate';
    const AUTHENTICATE_CLS = 'authenticate';

    /**
     * Builds DOM element of the generic video player.
     * Current implementation is as per Brightcove video provider configuration.
     * More info and documentation - https://support.brightcove.com/brightcove-player-code-samples
     *
     *
     * @param {object} settings - video player settings. See setting breakdown below:
     *
     * settings.video - {Object} video object holding all properties like id, mediaId etc.
     * settings.target - {Node} target element where we want to append video player to.
     * settings.cssClass - {String} additional classes for styling purpose.
     * settings.embed - {String} value to determine if embeded video. Usually "default"
     * settings.controls - {Boolean} property to determine if we want to show player controls.
     * settings.autoplay - {Boolean} property to determine if video to be autoplayed.
     *
     */
    common.VideoPlayer = function (settings) {
        const _self = this;

        _self.id = 'player' + settings.video.id;
        _self.video = settings.video ? settings.video : null;
        _self.target = settings.target ? settings.target : null;
        _self.tags = settings.tags ? settings.tags : null;
        _self.cssClass = settings.cssClass ? settings.cssClass : '';
        _self.embed = settings.embed ? settings.embed : 'default';
        _self.controls = settings.controls ? 'controls' : '';
        _self.autoplay = settings.autoplay ? 'autoplay' : '';

        // exit player rendering if no video object or target element provided.
        if (!_self.video || !_self.target) {
            return;
        }

        /* if ( common.ssoReady() ) {
            _self.handleAuthentication();
        } else {
            document.addEventListener( common.constants.SSO.ready, () => {
                _self.handleAuthentication();
            } );
        } */
        _self.setListeners();
    };

    /**
     * This method is for creating and getting ready video player node with all attributes
     * Data attributes will later be picked up by Brightcove external script
     */
    common.VideoPlayer.prototype.create = function () {
        const _self = this;

        // player template model
        const model = {
            playerTagId: _self.id,
            mediaId: _self.video.mediaId,
            providerId: window.brightcoveAccountId,
            playerId: window.brightcovePlayerId,
            classes: _self.cssClass,
            embed: _self.embed,
            controls: _self.controls,
            autoplay: _self.autoplay
        };

        // created and render video player tag.
        // true flag to make video tag as DOM, not as a string.
        const playerElem = app.templating.render(
            model,
            VIDEO_PLAYER_TEMPLATE,
            true
        );

        // append player element
        _self.append(playerElem);

        // load player
        _self.load();
    };

    /**
     * Method to append player to dedicated target element.
     *
     * @param { DomElement } playerElem - html DOM element of the player node.
     */
    common.VideoPlayer.prototype.append = function (playerElem) {
        const _self = this;

        // append video player to target element
        _self.target.appendChild(playerElem);
    };

    /**
     * Method to load video player when we need it eg. delaying dynamicly loaded player or newly added player, etc.
     * more details can be found at links below:
     * https://support.brightcove.com/using-bc-and-getplayer-methods
     * https://support.brightcove.com/delaying-player-instantiation
     */
    common.VideoPlayer.prototype.load = function () {
        const _self = this;

        // bc() is brightcove video provider method from externally loaded dependancy
        // used to instantiate a video player at a time of your choosing
        if (bc !== 'undefined') {
            // eslint-disable-line no-undef
            bc(_self.id); // eslint-disable-line no-undef
        }

        // fire event to alert player is ready, uses custom event namespace: player{BC_MEDIA_ID}Ready
        videojs.getPlayer(_self.id).ready(function () {
            // eslint-disable-line no-undef
            _self.bcPlayer = this;
            common.fireEvent(`${_self.id}Ready`, _self.bcPlayer, document);
        });
    };

    /**
     * Public method to be called when we want to load new video source for the current player
     *
     * @param {Array} tags - tags from current video (if not passed explicitly on method will fallback to [])
     * @param mediaId - media id represents the video we should load up
     * @param playerId - optional player id, if not provided then we grab current player
     *
     * more details:
     * https://support.brightcove.com/brightcove-player-sample-dynamically-change-source-videos
     */
    common.VideoPlayer.prototype.loadNewVideo = function (
        mediaId,
        playerId,
        tags, 
        autoplay=false
    ) {
        const _self = this;

        // no media id provided then no player video source change
        if (!mediaId) {
            console.warn(
                'Attempt to load new video unsuccessful as no media ID has been provided'
            );
            return;
        }

        if (tags) {
            const authBlocked = common.content.hasTag(tags, AUTH_TAG);
            /**
             * If video is auth blocked and user is not current authenticated, add authentication wall to video and remove autoplay functionality
             * This will prevent a user being able to play a video by clicking on the video player element
             */

            //COMMNENTING OUT AUTHENTICATION UNTIL SSO IS SET UP
            /* if ( authBlocked && !common.userIsAuthenticated() ) {
                _self.target.classList.add( AUTHENTICATE_CLS );
                this.setAutoplay( playerId, false ); // Remove autoplay to stop video from playing immediately after selection
            } else { */
            _self.target.classList.remove(AUTHENTICATE_CLS);
            //}
        } else {
            _self.target.classList.remove(AUTHENTICATE_CLS);
            console.warn(
                'WARNING: No tags passed on common.VideoPlayer.loadNewVideo. Unable to determine whether this video requires authentication or not...'
            );
        }
        const player = playerId ? playerId : _self.id;

        // get current player with Brightcove function and check if it is ready for new media
        videojs.getPlayer(player).ready(function () {
            // eslint-disable-line no-undef
            this.catalog.getVideo(mediaId, (error, video) => {
                this.catalog.load(video);
                _self.tags = tags; // Update tags on this video instance from the newly loaded video
                this.setAutoplay( player, autoplay );
                if (error) {
                    console.error(error);
                }
            });
        });
    };

    /**
     * Method to pause player for a given player id
     *
     * @param {string} playerId - optional player id, if not provided then we grab current player
     */
    common.VideoPlayer.prototype.pause = function (playerId) {
        const _self = this;
        const player = playerId ? playerId : _self.id;

        videojs.getPlayer(player).ready(function () {
            // eslint-disable-line no-undef
            this.pause();
        });
    };

    /**
     * Method to play player for a given player id
     *
     * @param {string} playerId - optional player id, if not provided then we grab current player
     */
    common.VideoPlayer.prototype.play = function (playerId) {
        const _self = this;
        if (_self.tags) {
            const authBlocked = common.content.hasTag(_self, AUTH_TAG);

            /**
             * If video is auth blocked and user is not current authenticated, open SSO modal and exit function before playing video
             * This will prevent a user being able to play a video by triggering a play event from elsewhere
             */
            if (authBlocked && !common.userIsAuthenticated()) {
                _self.triggerAuthModal();
                return;
            }
        } else {
            console.warn(
                'WARNING: No tags passed on common.VideoPlayer.play. Unable to determine whether this video requires authentication or not...'
            );
        }
        const player = playerId ? playerId : _self.id;
        videojs.getPlayer(player).ready(function () {
            // eslint-disable-line no-undef
            this.play();
        });
    };
    /**
     * Method to reset video to the start
     *
     * @param {string} playerId - optional player id, if not provided then we grab current player
     */
    common.VideoPlayer.prototype.resetToStart = function resetToStart(
        playerId
    ) {
        let _self = this;
        let player = playerId ? playerId : _self.id;
        videojs.getPlayer(player).ready(function () {
            // eslint-disable-line no-undef
            this.currentTime(0);
            this.pause();
        });
    };
    /**
     * Method to set autoplay programmatically
     *
     * @param {number} playerId - optional player id, if not provided then we grab current player
     * @param {boolean} autoplay - whether we want the video to autoplay or not
     */
    common.VideoPlayer.prototype.setAutoplay = function setAutoplay(
        playerId,
        autoplay
    ) {
        let _self = this;
        let player = playerId ? playerId : _self.id;
        videojs.getPlayer(player).ready(function () {
            // eslint-disable-line no-undef
            this.autoplay(autoplay);
        });
    };
    /**
     * Method to get current autoplay value
     *
     * @param {number} playerId - optional player id, if not provided then we grab current player
     * @returns {Promise} resolves with current autoplay setting (true/false)
     */
    common.VideoPlayer.prototype.getAutoplay = function getAutoplay(playerId) {
        let _self = this;
        let player = playerId ? playerId : _self.id;
        return new Promise((resolve) => {
            videojs.getPlayer(player).ready(function () {
                // eslint-disable-line no-undef
                resolve(this.autoplay());
            });
        });
    };
    /**
     * Sets listeners on target video element
     */
    common.VideoPlayer.prototype.setListeners = function () {
        const _self = this;
        _self.target.addEventListener('click', () => {
            if (_self.target.classList.contains(AUTHENTICATE_CLS)) {
                _self.triggerAuthModal();
            }
        });
    };
    /**
     * If video requires authentication and user is not autenticated, add SSO wall to video by applying class. Otherwise remove it.
     */
    common.VideoPlayer.prototype.handleAuthentication = function () {
        const _self = this;

        /*if ( !common.userIsAuthenticated() && _self.videoRequiresAuth() ) {
            _self.target.classList.add( AUTHENTICATE_CLS );
        } else {*/
        _self.target.classList.remove(AUTHENTICATE_CLS);
        //}
    };
    /**
     * Checks whether the current video requires authentication
     *
     * @returns {boolean} whether video requires authentication or not
     */
    common.VideoPlayer.prototype.videoRequiresAuth = function () {
        const _self = this;
        let requiresAuth = false;
        if (_self.tags && common.content.hasTag(_self, AUTH_TAG)) {
            requiresAuth = true;
        }
        return requiresAuth;
    };
    /**
     * Fires custom event to signal we want to open SSO authentication modal (if not already open)
     */
    common.VideoPlayer.prototype.triggerAuthModal = function () {
        common.openSSOModal(
            PULSE.I18N.lookup('label.sso-modal.description.video')
        );
        this.pause();
    };
})(PULSE.app, PULSE.app.common);
