(function (app, common) {
    'use strict';
    /**
     * Checks if SSO object is initialised and ready for use
     *
     * @returns {boolean} - true if SSO is ready
     */
    common.ssoReady = () => app.SSO && app.SSO.ready;
    /**
     * Checks if user is authenticated
     *
     * @returns {boolean} - true if user is authenticated
     */
    common.userIsAuthenticated = () =>
        common.ssoReady() && app.SSO.authenticated;
    /**
     * Fires custom event to signal for SSO modal to open
     *
     * @param {string} description - option custom description to show in modal at point of execution
     */
    common.openSSOModal = (description) => {
        common.fireEvent(common.constants.SSO_EVENTS.OPEN_MODAL, {
            description
        });
    };
    /**
     * Generates keycloak register url with a custom redirect URI which the user will be linked back to after registration
     *
     * @param {string} redirectUri - redirect URI (defaults to current page url)
     * @returns {string} keycloak register url
     */
    common.getSSORegisterUrl = (redirectUri = window.location.href) => {
        return app.SSO.createRegisterUrl({
            redirectUri: encodeURI(redirectUri),
            action: 'register',
            kcLocale: app.language
        });
    };
    /**
     * Generates keycloak login url with a custom redirect URI which the user will be linked back to after login
     *
     * @param {string} redirectUri - redirect URI (defaults to current page url)
     * @returns {string} keycloak login url
     */
    common.getSSOLoginUrl = (redirectUri = window.location.href) => {
        return app.SSO.createLoginUrl({
            redirectUri: encodeURI(redirectUri),
            kcLocale: app.language
        });
    };
})(PULSE.app, PULSE.app.common);
