(function (core, app, common) {
    common.statUrls = {
        MostRuns: 'most-runs',
        MostSixes: 'most-sixes',
        MostFours: 'most-fours',
        HighestScores: 'highest-scores',
        BestBattingStrikeRate: 'highest-strikerate',

        MostWickets: 'most-wickets',
        BestBowling: 'best-bowling-figures',
        BowlingAverage: 'best-averages',
        BestEconomy: 'best-economy',

        AllFairplay: 'all-fairplay',
        PlayerPoints: 'player-points',
        EmergingPlayerPoints: 'emerging-players'
    };

    /**
     * creates content path based on the type and parameters
     *
     * @param {string} type Type of content
     * @param {object} params query params (to be used as GET params)
     * @param {string} lang   the API language; defaults to english
     * @param {number} id     ID of the item if requesting only one
     * @returns {string} url Content url
     */
    common.createContentPath = function (type, params, lang, id) {
        let contentType = type ? type.toLowerCase() + '/' : '';
        let url = app.environment.api + '/content/' + app.account + '/' + contentType + (lang ? lang : app.defaultLanguage) + '/' + (id ? id : '');

        if (params) {
            url += '?' + core.url.buildQueryString(params);
        }

        return url;
    };

    /**
     * creates canary stream path
     *
     * @param {object} config Configuration for the Canary feed
     * @param {string} type Type of feed
     * @returns {string} url Canary url
     */
    common.createCanaryStreamPath = function (config) {
        let url = app.environment.canary,
            params = '';

        if (config && config.pageSize) {
            params += '?pageSize=' + config.pageSize;
        }

        if (config && config.network) {
            params += (params ? '&' : '?') + 'network=' + config.network;
        }

        if (app.environment.label === 'local') {
            return url + params;
        }

        if (config && config.references) {
            return (
                url +
                'account/' +
                PULSE.app.environment.cmsAccount +
                '/contentref/' +
                config.references +
                '/posts' +
                params
            );
        }

        if (config && config.streamId) {
            return url + '/stream/' + config.streamId + '/posts' + params;
        }

        return url + 'stream/combinedstream/posts' + params;
    };

    /**
     * creates canary path based a strean id
     *
     * @param {int} id Stream id
     * @returns {string} url Canary url
     */
    common.createCanaryPathFromId = function (id) {
        let url = app.environment.canary + 'stream/' + id + '/posts';

        return url;
    };

    common.createPollQuestionUrl = function (pollName) {
        let url = app.environment.pollQuestions + '/' + pollName + '/poll.js';

        return url;
    };

    common.createPollAnswerUrl = function (questionId, optionId) {
        let url =
            app.environment.pollAnswer +
            '?id=' +
            questionId +
            '&option=' +
            optionId +
            '&callback=JSON_CALLBACK';

        return url;
    };

    common.makeNewerThanLiveBlogUrl = function (
        id,
        newerThan,
        maxItems,
        params = {}
    ) {
        let url = common.makeLiveBlogUrl(id);
        url += 'newerThan/' + newerThan + '/';

        let extendedParams = core.object.extend({}, params, {
            maxResults: maxItems
        });

        return url + core.url.buildQueryString(extendedParams, true);
    };

    common.makeBelowPositionBlogUrl = function (
        id,
        position,
        maxItems,
        params = {}
    ) {
        let url = this.makeLiveBlogUrl(id);
        url += 'below/' + position + '/';

        let extendedParams = core.object.extend({}, params, {
            maxResults: maxItems
        });

        return url + core.url.buildQueryString(extendedParams, true);
    };

    common.makeAbovePositionBlogUrl = function (
        id,
        position,
        maxItems,
        params = {}
    ) {
        let url = this.makeLiveBlogUrl(id);

        url += 'above/' + position + '/';

        let extendedParams = core.object.extend({}, params, {
            maxResults: maxItems
        });

        return url + core.url.buildQueryString(extendedParams, true);
    };

    common.makeLiveBlogUrl = function (id, maxItems, params = {}) {
        let url =
            app.environment.api +
            '/liveblog/' +
            app.account +
            '/' +
            id +
            '/' +
            app.defaultLanguage +
            '/';

        let extendedParams = core.object.extend({}, params, {
            maxResults: maxItems
        });

        return url + core.url.buildQueryString(extendedParams, true);
    };

    common.makeLiveBlogsQueryUrl = function (params) {
        let url =
            app.environment.api +
            '/liveblog/' +
            app.account +
            '/' +
            app.defaultLanguage +
            '/';

        return url + (params ? core.url.buildQueryString(params, true) : '');
    };

    /**
     * creates API path based on a string and parameters
     *
     * @param {string} path Object path for the API object
     * @param {object} params Url parameters
     * @returns {string} url API url
     */
    common.createApiPath = function (path, params) {
        let thisPath = core.object.objectByString(app.environment.api, path);
        let paramArray = [];
        for (let key in params) {
            if (thisPath.indexOf('{' + key + '}') > -1) {
                thisPath = thisPath.replace('{' + key + '}', params[key]);
            } else if (typeof params[key] !== 'undefined') {
                paramArray.push(key + '=' + params[key]);
            }
        }
        let url = thisPath;
        if (paramArray.length > 0) {
            url += '?' + paramArray.join('&');
        }
        return url;
    };

    /**
     * generate a url for a some content
     *
     * @param {string} type            Type of content
     * @param {object | number} content  Id of content or the content itself
     * @param {string} restriction     For playlist, if there's a content restriction
     * @returns {string} url Link to content
     */
    common.generateUrl = function (type, content, restriction) {
        let base = '//' + app.environment.domain;
        let contentType = (type || '').toLowerCase();
        let contentResponse, url, id;

        // this is ugly
        if (typeof content === 'object' && content.id) {
            contentResponse = id;
            id = content.id;
        } else {
            id = content;
        }

        let playlistRestriction = restriction ? restriction.toLowerCase() : '';

        switch (contentType) {
            case 'text':
                url = base + '/news/' + id;
                if (contentResponse && contentResponse.title) {
                    return url + '/' + common.urlify(contentResponse.title);
                }

                return url;

            case 'video':
                url = base + '/video/' + id;
                if (contentResponse && contentResponse.title) {
                    return url + '/' + common.urlify(contentResponse.title);
                }

                return url;

            case 'playlist':
                if (playlistRestriction === 'photo') {
                    url = base + '/photos/' + id;
                    if (contentResponse && contentResponse.title) {
                        return url + '/' + common.urlify(contentResponse.title);
                    }

                    return url;
                } else if (playlistRestriction === 'video') {
                    return base + '/video/categories/' + id;
                }
                return '';
            case 'photo':
                url = '/' + id;
                if (contentResponse && contentResponse.description) {
                    return (
                        url + '/' + common.urlify(contentResponse.description)
                    );
                }

                return url;
            default:
                return '';
        }
    };

    /**
     *  Site navigation
     *
     *  @param {string} matchId           the match ID name (e.g., ipl2018-54)
     *  @param {string} tab               the tab the match centre should be opening on (optional)
     *  @param {cricket.Tournament} tourn the instance of the tournament
     *
     *  @returns {string} url
     */
    common.getMatchURL = function (matchId, tab, tourn) {
        let tournament = tourn || app.currentTournament;

        if (!matchId) {
            return null;
        }

        let array = matchId.split('-');
        let number = array[array.length - 1];
        if (!isNaN(number)) {
            if (tournament.supportsMC) {
                return (
                    '/match/' +
                    tournament.year +
                    '/' +
                    number +
                    (tab ? '?tab=' + tab : '')
                );
            }

            return '/archive/' + tournament.year + '/' + number;
        }

        return null;
    };

    common.getTeamURL = function (team) {
        let tournament = app.currentTournament;
        if (
            !team.id ||
            !team.fullName ||
            -1 === tournament.teams.indexOf(team.id)
        ) {
            return null;
        }

        if (team && team.fullName) {
            return '/teams/' + common.urlify(team.fullName);
        }

        return null;
    };

    // player page URL
    common.getPlayerURL = function (
        playerId,
        playerFullName,
        teamId,
        teamFullName
    ) {
        let tournament = app.currentTournament;
        if (
            !tournament.supportsPlayerLinks ||
            !teamId ||
            !teamFullName ||
            !playerId ||
            !playerFullName
        ) {
            return null;
        }

        let teamURL = common.getTeamURL({ id: teamId, fullName: teamFullName });

        if (teamURL) {
            return (
                teamURL +
                '/squad/' +
                playerId +
                '/' +
                common.urlify(playerFullName)
            );
        }

        return null;
    };

    common.getTeamPlayer = function (playerID, language, additionalParams = null) {
        if (!playerID) {
            return null;
        }

        return `${app.environment.api}/rugby/v3/stats/player/${playerID}?language=${language}${ additionalParams ? '&' + additionalParams : '' }`;
    };

    common.getVenueUrl = function (venueId, venueName) {
        if (!venueId || !venueName) {
            return null;
        }

        return (
            '/venues/' + venueId + '/' + common.urlify(venueName) + '/overview'
        );
    };

    // full stats of type URL
    common.getStatsUrlFor = function (type, year) {
        let tournament = app.currentTournament;
        if (!type || !common.statUrls[type]) {
            return null;
        }

        return (
            '/stats/' +
            (year || tournament.year || 'all-time') +
            '/' +
            common.statUrls[type]
        );
    };

    common.urlify = function (str) {
        return str
            .toLowerCase()
            .replace(/[^a-z0-9]+/gi, '-')
            .replace(/-{2,}/g, '-')
            .replace(/^-|-$/g, '');
    };
})(PULSE.core, PULSE.app, PULSE.app.common);
