/**
 * Coupled set of date string and moment object
 *
 * @typedef {object} momentCouple
 * @property {moment|Date} date the moment Object
 * @property {string} string the generated string
 * @property {string} std locale independent date string ( DD-MM-YYYY )
 */

(function (common, app, core, moment) {
    const SECS = 60;
    const MINS = 60;
    const HOURS = 24;
    const toMillis = 1000;
    const oneDay = SECS * MINS * HOURS * toMillis;

    /**
     * Outputs date markup with helpers for Japanese output
     * Accompanying stylesheet flips order when Japanese language detected
     * @param {Integer} millis - value for date to render
     * @param {String} cssClasses - classes to be output in the main container
     * @param {Boolean} shortenedDay - 'true' to render 'Thu' instead of 'Thursday' (language agnostic)
     * @param {Boolean} shortenedMonth - 'true' to render 'Oct' instead of 'October' (language agnostic)
     * @param {Boolean} isOrdinalDate - 'true' to render '4th' instead of '4' (language agnostic)
     * @returns {String} HTML Element containing date
     */
    common.dateTranslated = function( millis, cssClasses = '', shortenedDay = false, shortenedMonth = false,  isOrdinalDate = false ) {
        const isJapanese = app.language === 'ja';
        const date = moment( millis );
        const day = shortenedDay ? date.format( 'ddd' ) : date.format( 'dddd' );
        const dayNum = isOrdinalDate ? date.format( 'Do' ) : date.format( 'D' );
        const months = shortenedMonth ? date.format( 'MMM' ) : date.format( 'MMMM' ) + ',';
        const years = date.format( 'YYYY' );

        const japaneseDay = '<span class="date__jpn-char">日</span>';
        const japaneseMonth = `<span class="date__jpn-char">${date.format( 'MMM' )}</span>`;
        const japaneseYear = '<span class="date__jpn-char">年</span>';

        const dateElement = `<div class="date ${ cssClasses }">
            <span class="date__unit date__unit--day">
                ${ day }
            </span>
            <span class="date__unit date__unit--day-number">
                ${ dayNum }${ isJapanese ? japaneseDay : '' }
            </span>
            <span class="date__unit date__unit--month">
                ${ isJapanese ? japaneseMonth : months }
            </span>
            <span class="date__unit date__unit--year">
                ${ years }${ isJapanese ? japaneseYear : '' }
            </span>
        </div>`;

        return dateElement;
    };

    /**
     * Use momentJS to get a locale-observant string with a specified format
     * will return moment object as well as string
     *
     * @param { Mixed } start - unix timestamp or Date
     * @param { Mixed } end - unix timestamp or Date
     * @param {string} format - date representation
     * @param {boolean} inclusive if true will include start  day in response
     * @returns {momentCouple[]} array of days with moment day and string included in each index position
     */
    common.momentGetDaysFromRange = function (start, end, format, inclusive) {
        let startAsDate = start instanceof Date ? start : new Date(start);
        let endAsDate = end instanceof Date ? end : new Date(end);

        let startTime = startAsDate.getTime();

        let days = [];

        let current = startTime;

        if (
            inclusive &&
            startAsDate.toLocaleDateString() !== endAsDate.toLocaleDateString()
        ) {
            days.push({
                date: startAsDate,
                string:
                    moment && format
                        ? moment(startAsDate)
                              .locale(app.language)
                              .format(format)
                        : startAsDate.toLocaleDateString(),
                std: moment ? moment(startAsDate).format('DD-MM-YYYY') : false
            });
        }

        // add a day until the date reaches the end date
        do {
            current = current + oneDay;
            let asDate = new Date(current);

            days.push({
                date: asDate,
                string:
                    moment && format
                        ? moment(asDate).locale(app.language).format(format)
                        : asDate.toLocaleDateString(),
                std: moment ? moment(asDate).format('DD-MM-YYYY') : false
            });
        } while (current < endAsDate.getTime());

        return days;
    };

    /**
     * get the time from a date
     *
     * @param {string} date date string
     *
     * @returns {string} date as time
     */
    common.getTime = function (date) {
        if (date) {
            let obj = typeof date === 'string' ? new Date(date) : date;
            let hours = obj.getHours();
            let minutes = obj.getMinutes();
            const format = 10;

            if (hours < format) {
                hours = '0' + hours;
            }
            if (minutes < format) {
                minutes = '0' + minutes;
            }

            return hours + ':' + minutes;
        }

        return null;
    };

    /**
     * Get the time since a specific date
     *
     * @param {Date} date - Date to be calculated from now
     * @param {object} format - Optional format for output
     * @returns {string} output Amount of time since date
     */
    common.getSinceString = function (date, format) {
        if (date) {
            let now = new Date();
            let diff = Math.floor((now - date) / toMillis);
            let output;

            if (diff <= 0) {
                return format ? format.justNow : 'just now';
            } else if (diff < MINS) {
                output = Math.round(diff);
                return output + (format ? format.seconds : 's');
            } else if (diff < MINS * SECS) {
                output = Math.round(diff / MINS);
                return output + (format ? format.minutes : 'm');
            } else if (diff < MINS * SECS * HOURS) {
                output = Math.round(diff / (MINS * SECS));
                return output + (format ? format.hours : 'h');
            }

            output = Math.round(diff / (MINS * SECS * HOURS));
            return output + (format ? format.days : 'd');
        }

        return '';
    };

    /**
     * Get duration in time format mm:ss
     *
     * @param {Int} duration - number of seconds
     * @returns {string} output duration in format mm:ss
     */
    common.durationToTime = function (duration) {
        let secNum = parseInt(duration, 10);
        const hourInSecs = 3600;

        if (secNum) {
            let hours = Math.floor(secNum / hourInSecs);
            let minutes = Math.floor((secNum - hours * hourInSecs) / SECS);
            let seconds = secNum - hours * hourInSecs - minutes * SECS;
            const format = 10;

            if (hours < format) {
                hours = '0' + hours;
            }
            if (minutes < format) {
                minutes = '0' + minutes;
            }
            if (seconds < format) {
                seconds = '0' + seconds;
            }

            let minSec = minutes + ':' + seconds;

            return hours > 0 ? hours + ':' + minSec : minSec;
        }

        return '00:00';
    };

    /**
     * Fixes date and returns date label (YYYY-MM-DD) when incorrect date starts with a 0 instead of the final day of the previous month
     *
     * @param {String} monthString - month from incorrect date
     * @param {String} year - year from incorrect date
     * @returns {String} corrected date label
     */
    common.getFinalDayOfMonth = function ( monthString, year ) {

        let newDay,
        newMonth = monthString.slice(0, 2),
        newYear = year.slice(0, 4),
        month = Number( newMonth );

        if ( month === 1 ) {
            newDay = "31";
            newMonth = "12";
            newYear = ( year - 1 ).toString();
        } else if ( month === 2 || month === 4 || month === 6 || month === 8 || month ===  9 || month === 11 ) {
            newDay = "31";
            newMonth = ( month - 1 ).toString();
        } else if ( month === 5 || month === 7 || month === 10 || month === 12 ) {
            newDay = "30"; 
            newMonth = ( month - 1 ).toString();
        } else if ( month === 3 && year === "2028" ) {
            newDay = "29"; 
            newMonth = "2";
        } else if ( month === 3 )  {
            newDay = "28"; 
            newMonth = "2";
        }

        return newYear + '-' + ( newMonth.length != 2 ? '0' + newMonth : newMonth ) + '-' + ( newDay.length != 2 ? '0' + newDay : newDay );
    };

})(PULSE.app.common, PULSE.app, PULSE.core, moment);
